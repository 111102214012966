import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/Button';

const commentSchema = z.object({
  text: z.string().min(1, 'Le commentaire ne peut pas être vide'),
});

type CommentFormInputs = z.infer<typeof commentSchema>;

interface CommentFormProps {
  onSubmit: (data: CommentFormInputs) => Promise<void>;
  isLoading?: boolean;
}

export function CommentForm({ onSubmit, isLoading }: CommentFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CommentFormInputs>({
    resolver: zodResolver(commentSchema),
  });

  const handleFormSubmit = async (data: CommentFormInputs) => {
    try {
      await onSubmit(data);
      reset();
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
      <div>
        <textarea
          {...register('text')}
          rows={3}
          className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
          placeholder="Votre commentaire..."
          disabled={isLoading}
        />
        {errors.text && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {errors.text.message}
          </p>
        )}
      </div>

      <Button
        type="submit"
        disabled={isLoading}
        className="w-full sm:w-auto"
      >
        {isLoading ? 'Envoi...' : 'Envoyer'}
      </Button>
    </form>
  );
}