import { RefreshCw } from 'lucide-react';
import { useFirebaseSync } from '../../lib/firebase/hooks/useFirebaseSync';

export function SyncIndicator() {
  const { isSyncing } = useFirebaseSync();

  if (!isSyncing) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2">
      <RefreshCw className="h-4 w-4 animate-spin" />
      <span>Synchronisation en cours...</span>
    </div>
  );
}