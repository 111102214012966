import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '../types';
import { auth, db } from './firebase/config';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, username: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  setError: (error: string | null) => void;
}

const googleProvider = new GoogleAuthProvider();

export const useAuth = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      loading: false,
      error: null,
      login: async (email: string, password: string) => {
        try {
          set({ loading: true, error: null });
          const result = await signInWithEmailAndPassword(auth, email, password);
          const userDoc = await getDoc(doc(db, 'users', result.user.uid));
          
          if (userDoc.exists()) {
            const userData = userDoc.data() as User;
            set({ 
              user: userData,
              isAuthenticated: true,
              loading: false 
            });
          }
        } catch (error) {
          set({ 
            error: 'Erreur lors de la connexion',
            loading: false 
          });
          throw error;
        }
      },
      register: async (email: string, password: string, username: string) => {
        try {
          set({ loading: true, error: null });
          const result = await createUserWithEmailAndPassword(auth, email, password);
          
          const newUser: User = {
            id: result.user.uid,
            email,
            username,
            role: 'user',
            createdAt: new Date()
          };

          await setDoc(doc(db, 'users', newUser.id), newUser);
          set({ 
            user: newUser,
            isAuthenticated: true,
            loading: false 
          });
        } catch (error) {
          set({ 
            error: 'Erreur lors de l\'inscription',
            loading: false 
          });
          throw error;
        }
      },
      signInWithGoogle: async () => {
        try {
          set({ loading: true, error: null });
          const result = await signInWithPopup(auth, googleProvider);
          const { user: googleUser } = result;

          const userDoc = await getDoc(doc(db, 'users', googleUser.uid));
          
          if (!userDoc.exists()) {
            const newUser: User = {
              id: googleUser.uid,
              email: googleUser.email!,
              username: googleUser.displayName || 'Utilisateur',
              role: 'user',
              createdAt: new Date(),
              avatarUrl: googleUser.photoURL || undefined
            };

            await setDoc(doc(db, 'users', newUser.id), newUser);
            set({ 
              user: newUser,
              isAuthenticated: true,
              loading: false 
            });
          } else {
            const userData = userDoc.data() as User;
            set({ 
              user: userData,
              isAuthenticated: true,
              loading: false 
            });
          }
        } catch (error) {
          set({ 
            error: 'Erreur lors de la connexion avec Google',
            loading: false 
          });
          throw error;
        }
      },
      logout: async () => {
        try {
          await firebaseSignOut(auth);
          set({ 
            user: null, 
            isAuthenticated: false 
          });
        } catch (error) {
          console.error('Logout error:', error);
          throw error;
        }
      },
      setError: (error) => set({ error })
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ user: state.user, isAuthenticated: state.isAuthenticated })
    }
  )
);