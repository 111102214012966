import { create } from 'zustand';
import { doc, updateDoc, increment, arrayUnion, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { UserRewards, RewardHistory, REWARD_AMOUNTS } from './types';

interface RewardsState {
  rewards: UserRewards | null;
  loading: boolean;
  error: string | null;
  addSuncoins: (userId: string, amount: number, reason: string) => Promise<void>;
  fetchRewards: (userId: string) => Promise<void>;
}

export const useRewards = create<RewardsState>((set) => ({
  rewards: null,
  loading: false,
  error: null,

  addSuncoins: async (userId: string, amount: number, reason: string) => {
    try {
      set({ loading: true, error: null });
      
      const rewardRef = doc(db, 'rewards', userId);
      const rewardDoc = await getDoc(rewardRef);

      const rewardHistory: RewardHistory = {
        id: crypto.randomUUID(),
        amount,
        reason,
        timestamp: new Date()
      };

      if (!rewardDoc.exists()) {
        await setDoc(rewardRef, {
          suncoins: amount,
          lastUpdated: Timestamp.now(),
          history: [rewardHistory]
        });
      } else {
        await updateDoc(rewardRef, {
          suncoins: increment(amount),
          lastUpdated: Timestamp.now(),
          'history': arrayUnion({
            ...rewardHistory,
            timestamp: Timestamp.fromDate(rewardHistory.timestamp)
          })
        });
      }

      // Recharger les récompenses après la mise à jour
      const updatedDoc = await getDoc(rewardRef);
      if (updatedDoc.exists()) {
        const data = updatedDoc.data();
        set({ 
          rewards: {
            suncoins: data.suncoins || 0,
            lastUpdated: data.lastUpdated?.toDate() || new Date(),
            history: (data.history || []).map((item: any) => ({
              ...item,
              timestamp: item.timestamp?.toDate() || new Date()
            }))
          } as UserRewards,
          loading: false 
        });
      }
    } catch (error) {
      console.error('Error adding suncoins:', error);
      set({ error: 'Erreur lors de l\'ajout des SUNCOINS', loading: false });
    }
  },

  fetchRewards: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      
      const rewardDoc = await getDoc(doc(db, 'rewards', userId));
      if (rewardDoc.exists()) {
        const data = rewardDoc.data();
        set({ 
          rewards: {
            suncoins: data.suncoins || 0,
            lastUpdated: data.lastUpdated?.toDate() || new Date(),
            history: (data.history || []).map((item: any) => ({
              ...item,
              timestamp: item.timestamp?.toDate() || new Date()
            }))
          } as UserRewards,
          loading: false 
        });
      } else {
        set({ 
          rewards: {
            suncoins: 0,
            lastUpdated: new Date(),
            history: []
          },
          loading: false 
        });
      }
    } catch (error) {
      console.error('Error fetching rewards:', error);
      set({ error: 'Erreur lors de la récupération des SUNCOINS', loading: false });
    }
  }
}));