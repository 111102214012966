import { useEffect } from 'react';
import { useDeals } from '../lib/stores/deals';
import { useFilters } from '../lib/filters';
import { DealList } from '../components/deals/DealList';
import { SearchFilters } from '../components/deals/SearchFilters';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { ErrorMessage } from '../components/ui/ErrorMessage';
import { EmptyState } from '../components/deals/EmptyState';
import { useInteractions } from '../hooks/useInteractions';
import { useAuth } from '../lib/auth';

function HomePage() {
  const { deals, loading, error, initializeRealtimeDeals } = useDeals();
  const { search, category, sort } = useFilters();
  const { likeDeal, dislikeDeal } = useInteractions();
  const { user } = useAuth();

  useEffect(() => {
    const unsubscribe = initializeRealtimeDeals();
    return () => unsubscribe();
  }, [initializeRealtimeDeals]);

  const handleLike = async (id: string) => {
    if (!user) return;
    await likeDeal(id, user.id);
  };

  const handleDislike = async (id: string) => {
    if (!user) return;
    await dislikeDeal(id, user.id);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <ErrorMessage
        message="Impossible de charger les bons plans"
        onRetry={() => initializeRealtimeDeals()}
      />
    );
  }

  const filteredDeals = useDeals.getState().filterDeals(search, category, sort);

  return (
    <div className="space-y-8">
      <SearchFilters />
      
      {filteredDeals.length > 0 ? (
        <section>
          <h2 className="text-2xl font-bold mb-6">
            {filteredDeals.length} 
            {filteredDeals.length > 1 ? ' bons plans trouvés' : ' bon plan trouvé'}
          </h2>
          <DealList
            deals={filteredDeals}
            onLike={handleLike}
            onDislike={handleDislike}
          />
        </section>
      ) : (
        <EmptyState
          message={
            search
              ? "Aucun bon plan ne correspond à votre recherche"
              : "Aucun bon plan disponible"
          }
        />
      )}
    </div>
  );
}

export default HomePage;