import { useState, useEffect } from 'react';
import { useNetworkStatus } from '../../../hooks/useNetworkStatus';
import { db } from '../config';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useNotify } from '../../../hooks/useNotify';

export function useFirebaseSync() {
  const isOnline = useNetworkStatus();
  const [isSyncing, setIsSyncing] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    if (isOnline) {
      syncPendingData();
    }
  }, [isOnline]);

  const syncPendingData = async () => {
    if (isSyncing) return;

    try {
      setIsSyncing(true);

      // Récupérer les documents en attente de synchronisation
      const collections = ['deals', 'posts', 'comments'];
      
      for (const collectionName of collections) {
        const q = query(
          collection(db, collectionName),
          where('syncStatus', '==', 'pending')
        );
        
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          notify.info(`Synchronisation de ${collectionName} en cours...`);
          
          // Synchroniser chaque document
          for (const docSnapshot of snapshot.docs) {
            const docRef = doc(db, collectionName, docSnapshot.id);
            await updateDoc(docRef, {
              syncStatus: 'synced',
              updatedAt: new Date()
            });
          }
        }
      }

      notify.success('Synchronisation terminée');
    } catch (error) {
      console.error('Error syncing pending data:', error);
      notify.error('Erreur lors de la synchronisation');
    } finally {
      setIsSyncing(false);
    }
  };

  return {
    isSyncing,
    syncPendingData
  };
}