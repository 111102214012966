import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Politique de Confidentialité</h1>

      <div className="prose dark:prose-invert">
        <p className="text-sm text-gray-500 mb-8">Dernière mise à jour : {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Collecte des données</h2>
          <p>
            Nous collectons les données suivantes :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Données d'inscription (email, nom d'utilisateur)</li>
            <li>Données de profil (avatar, préférences)</li>
            <li>Données d'utilisation (bons plans publiés, commentaires)</li>
            <li>Données techniques (adresse IP, cookies)</li>
            <li>Données de géolocalisation (avec votre consentement)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Utilisation des données</h2>
          <p>
            Vos données sont utilisées pour :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Fournir et améliorer nos services</li>
            <li>Personnaliser votre expérience</li>
            <li>Vous envoyer des notifications</li>
            <li>Assurer la sécurité de la plateforme</li>
            <li>Respecter nos obligations légales</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Partage des données</h2>
          <p>
            Nous ne partageons vos données qu'avec :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Nos sous-traitants techniques (hébergement, analyse)</li>
            <li>Les autorités en cas d'obligation légale</li>
            <li>D'autres utilisateurs (profil public uniquement)</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Sécurité des données</h2>
          <p>
            Nous mettons en œuvre des mesures de sécurité appropriées :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Chiffrement des données sensibles</li>
            <li>Accès restreint aux données personnelles</li>
            <li>Surveillance continue de la sécurité</li>
            <li>Formation de notre personnel</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Vos droits</h2>
          <p>
            Vous disposez des droits suivants :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Accès à vos données</li>
            <li>Rectification des données inexactes</li>
            <li>Suppression de vos données</li>
            <li>Opposition au traitement</li>
            <li>Portabilité des données</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Cookies</h2>
          <p>
            Nous utilisons des cookies pour :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Maintenir votre session</li>
            <li>Mémoriser vos préférences</li>
            <li>Analyser l'utilisation du site</li>
            <li>Personnaliser le contenu</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Conservation des données</h2>
          <p>
            Nous conservons vos données :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Pendant la durée de votre inscription pour les données de compte</li>
            <li>3 ans après votre dernière activité</li>
            <li>Selon les obligations légales pour certaines données</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Transferts internationaux</h2>
          <p>
            Vos données sont hébergées dans l'Union Européenne. En cas de transfert hors UE, 
            nous nous assurons que des garanties appropriées sont mises en place.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. Contact</h2>
          <p>
            Pour toute question concernant vos données personnelles :<br />
            Email : privacy@sundeals.io
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;