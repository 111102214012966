import { Deal } from '../../types';
import { DealCard } from './DealCard';
import { DealCardSkeleton } from './DealCardSkeleton';

interface DealListProps {
  deals: Deal[];
  onLike: (id: string) => void;
  onDislike: (id: string) => void;
  loading?: boolean;
}

export function DealList({ deals, onLike, onDislike, loading }: DealListProps) {
  if (loading) {
    return (
      <div 
        className="grid gap-6 md:grid-cols-2 lg:grid-cols-3"
        role="status"
        aria-label="Chargement des bons plans"
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <DealCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (deals.length === 0) {
    return (
      <div 
        className="text-center py-12"
        role="status"
        aria-label="Aucun bon plan disponible"
      >
        <p className="text-gray-500">Aucun bon plan pour le moment</p>
      </div>
    );
  }

  return (
    <div 
      className="grid gap-6 md:grid-cols-2 lg:grid-cols-3"
      role="feed"
      aria-label="Liste des bons plans"
    >
      {deals.map((deal) => (
        <DealCard
          key={deal.id}
          deal={deal}
          onLike={() => onLike(deal.id)}
          onDislike={() => onDislike(deal.id)}
        />
      ))}
    </div>
  );
}