export async function debugNotifications() {
  console.group('🔍 Diagnostic des notifications');
  
  // 1. Vérifier le support des notifications
  console.log('1. Support des notifications:');
  console.log('- Notifications API:', 'Notification' in window);
  console.log('- Service Worker API:', 'serviceWorker' in navigator);
  
  // 2. Vérifier les permissions
  console.log('\n2. Permissions:');
  console.log('- Status:', Notification.permission);
  
  // 3. Vérifier le Service Worker
  try {
    const registration = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
    console.log('\n3. Service Worker:');
    console.log('- Enregistré:', !!registration);
    console.log('- Scope:', registration?.scope);
    console.log('- État:', registration?.active ? 'Actif' : 'Inactif');
  } catch (error) {
    console.error('Erreur Service Worker:', error);
  }

  // 4. Vérifier la configuration Firebase
  console.log('\n4. Configuration Firebase:');
  console.log('- VAPID Key:', !!import.meta.env.VITE_FIREBASE_VAPID_KEY);
  console.log('- Messaging Sender ID:', !!import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID);

  console.groupEnd();
}