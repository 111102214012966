import { create } from 'zustand';
import { doc, updateDoc, arrayUnion, arrayRemove, increment, runTransaction } from 'firebase/firestore';
import { db } from '../firebase/config';

interface InteractionsState {
  loading: boolean;
  error: string | null;
  likeDeal: (dealId: string, userId: string) => Promise<void>;
  dislikeDeal: (dealId: string, userId: string) => Promise<void>;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
}

export const useInteractionsStore = create<InteractionsState>((set) => ({
  loading: false,
  error: null,

  likeDeal: async (dealId: string, userId: string) => {
    try {
      set({ loading: true, error: null });
      
      await runTransaction(db, async (transaction) => {
        const dealRef = doc(db, 'deals', dealId);
        const dealDoc = await transaction.get(dealRef);
        
        if (!dealDoc.exists()) {
          throw new Error('Deal not found');
        }

        const dealData = dealDoc.data();
        const isDisliked = dealData.dislikedBy?.includes(userId);
        const isLiked = dealData.likedBy?.includes(userId);

        if (isLiked) {
          await updateDoc(dealRef, {
            likes: increment(-1),
            likedBy: arrayRemove(userId)
          });
        } else {
          const updates: any = {
            likes: increment(1),
            likedBy: arrayUnion(userId)
          };

          if (isDisliked) {
            updates.dislikes = increment(-1);
            updates.dislikedBy = arrayRemove(userId);
          }

          await updateDoc(dealRef, updates);
        }
      });
    } catch (error) {
      console.error('Error liking deal:', error);
      set({ error: 'Erreur lors de l\'interaction' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  dislikeDeal: async (dealId: string, userId: string) => {
    try {
      set({ loading: true, error: null });
      
      await runTransaction(db, async (transaction) => {
        const dealRef = doc(db, 'deals', dealId);
        const dealDoc = await transaction.get(dealRef);
        
        if (!dealDoc.exists()) {
          throw new Error('Deal not found');
        }

        const dealData = dealDoc.data();
        const isDisliked = dealData.dislikedBy?.includes(userId);
        const isLiked = dealData.likedBy?.includes(userId);

        if (isDisliked) {
          await updateDoc(dealRef, {
            dislikes: increment(-1),
            dislikedBy: arrayRemove(userId)
          });
        } else {
          const updates: any = {
            dislikes: increment(1),
            dislikedBy: arrayUnion(userId)
          };

          if (isLiked) {
            updates.likes = increment(-1);
            updates.likedBy = arrayRemove(userId);
          }

          await updateDoc(dealRef, updates);
        }
      });
    } catch (error) {
      console.error('Error disliking deal:', error);
      set({ error: 'Erreur lors de l\'interaction' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error })
}));