import { Trophy, TrendingUp, Award, Users } from 'lucide-react';
import { useState, useEffect } from 'react';
import { SuncoinsDisplay } from './SuncoinsDisplay';
import { RewardsHistory } from './RewardsHistory';
import { ReferralCode } from './ReferralCode';
import { ReferralService } from '../../lib/rewards/referral';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface RewardsPanelProps {
  userId: string;
}

export function RewardsPanel({ userId }: RewardsPanelProps) {
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadReferralCode = async () => {
      try {
        const code = await ReferralService.getReferralCode(userId);
        setReferralCode(code);
      } catch (error) {
        console.error('Error loading referral code:', error);
      } finally {
        setLoading(false);
      }
    };

    loadReferralCode();
  }, [userId]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      <div className="grid gap-4 sm:grid-cols-3">
        <SuncoinsDisplay userId={userId} />
        
        <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-2">
            <Trophy className="w-6 h-6" />
            <div>
              <h3 className="font-semibold">Niveau</h3>
              <p className="text-2xl font-bold">2</p>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-lg p-4 shadow-md">
          <div className="flex items-center space-x-2">
            <Award className="w-6 h-6" />
            <div>
              <h3 className="font-semibold">Badges</h3>
              <p className="text-2xl font-bold">3</p>
            </div>
          </div>
        </div>
      </div>

      {/* Code de parrainage */}
      {referralCode && <ReferralCode code={referralCode} />}

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex items-center space-x-2 mb-6">
          <TrendingUp className="w-5 h-5 text-blue-500" />
          <h2 className="text-xl font-semibold">Progression</h2>
        </div>

        <div className="space-y-6">
          <div>
            <div className="flex justify-between mb-2">
              <span className="text-sm font-medium">Prochain niveau</span>
              <span className="text-sm text-gray-500">750/1000 XP</span>
            </div>
            <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2.5">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{ width: '75%' }}
              />
            </div>
          </div>

          <RewardsHistory userId={userId} limit={5} />
        </div>
      </div>
    </div>
  );
}