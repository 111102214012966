import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../lib/auth';
import { AvatarUpload } from '../components/user/AvatarUpload';
import { RewardsPanel } from '../components/rewards/RewardsPanel';
import { Button } from '../components/ui/Button';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';

function ProfilePage() {
  const { user, logout, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  if (loading || !user) {
    return <LoadingSpinner />;
  }

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <div className="max-w-4xl mx-auto px-4 space-y-8">
      <h1 className="text-2xl font-bold mb-6">Mon Profil</h1>

      {/* Informations du profil */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <div className="flex items-start space-x-6 mb-6">
          <AvatarUpload
            userId={user.id}
            currentAvatar={user.avatarUrl}
            onUpload={(url) => {
              // TODO: Mettre à jour l'avatar
            }}
          />
          
          <div className="flex-1">
            <h2 className="text-xl font-semibold">{user.username}</h2>
            <p className="text-gray-500 dark:text-gray-400">{user.email}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Membre depuis {new Date(user.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>

      {/* Panneau des récompenses */}
      <RewardsPanel userId={user.id} />

      {/* Bouton de déconnexion */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <Button
          variant="outline"
          onClick={handleLogout}
          className="w-full"
        >
          Se déconnecter
        </Button>
      </div>
    </div>
  );
}

export default ProfilePage;