import { useEffect } from 'react';
import { useNotificationStore } from '../../lib/notifications/store';

interface NotificationBadgeProps {
  count: number;
  className?: string;
}

export function NotificationBadge({ count, className = '' }: NotificationBadgeProps) {
  useEffect(() => {
    // Update favicon badge if supported
    if ('setAppBadge' in navigator) {
      if (count > 0) {
        navigator.setAppBadge(count);
      } else {
        navigator.clearAppBadge();
      }
    }
  }, [count]);

  if (count === 0) return null;

  return (
    <span 
      className={`absolute -top-1 -right-1 flex items-center justify-center min-w-[1.25rem] h-5 px-1 text-xs font-bold text-white bg-red-500 rounded-full ${className}`}
      role="status"
      aria-label={`${count} notifications non lues`}
    >
      {count > 99 ? '99+' : count}
    </span>
  );
}