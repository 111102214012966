import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp, MessageSquare } from 'lucide-react';
import { useCommunity } from '../../lib/stores/community';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface TrendingTopicsProps {
  compact?: boolean;
}

export function TrendingTopics({ compact = false }: TrendingTopicsProps) {
  const { trendingTopics, loading, error, fetchTrendingTopics } = useCommunity();

  useEffect(() => {
    fetchTrendingTopics();
  }, [fetchTrendingTopics]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-center text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-lg font-semibold mb-4">
        {compact ? 'Sujets populaires' : 'Tendances du moment'}
      </h2>

      <div className="space-y-4">
        {trendingTopics.slice(0, compact ? 3 : 5).map((topic) => (
          <div
            key={topic.id}
            className="flex items-center justify-between"
          >
            <Link
              to={`/deals?category=${topic.id}`}
              className="flex items-center space-x-3 flex-1"
            >
              <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                <TrendingUp className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div>
                <div className="font-medium">{topic.title}</div>
                <div className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
                  <MessageSquare className="w-4 h-4 mr-1" />
                  {topic.commentsCount} commentaires
                </div>
              </div>
            </Link>

            {!compact && (
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {topic.postsCount} posts
              </span>
            )}
          </div>
        ))}
      </div>

      {compact && trendingTopics.length > 3 && (
        <Link
          to="/commu?tab=trending"
          className="block text-center text-sm text-blue-600 dark:text-blue-400 hover:underline mt-4"
        >
          Voir toutes les tendances
        </Link>
      )}
    </div>
  );
}