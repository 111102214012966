import { useState, useCallback, useEffect } from 'react';
import { debounce } from '../lib/utils';

interface AddressSuggestion {
  display_name: string;
  lat: string;
  lon: string;
}

export function useAddressAutocomplete() {
  const [suggestions, setSuggestions] = useState<AddressSuggestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSuggestions = useCallback(
    debounce(async (query: string) => {
      if (!query || query.length < 3) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            query
          )}&countrycodes=fr,be&limit=5&addressdetails=1`
        );

        if (!response.ok) {
          throw new Error('Erreur lors de la recherche d\'adresses');
        }

        const data = await response.json();
        setSuggestions(data);
      } catch (err) {
        setError('Erreur lors de la recherche d\'adresses');
        console.error('Address suggestion error:', err);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      setSuggestions([]);
    };
  }, []);

  return {
    suggestions,
    loading,
    error,
    fetchSuggestions
  };
}