import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthForm } from '../components/auth/AuthForm';
import { ReferralService } from '../lib/rewards/referral';

function SignupPage() {
  const navigate = useNavigate();
  const referralCode = ReferralService.getReferralCodeFromUrl();

  useEffect(() => {
    if (!referralCode) {
      // Rediriger vers la page d'accueil si pas de code de parrainage
      navigate('/');
    }
  }, [referralCode, navigate]);

  return (
    <div className="max-w-md mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold text-center mb-6">
        Inscription avec parrainage
      </h1>
      
      {referralCode && (
        <div className="bg-blue-50 dark:bg-blue-900/20 p-4 rounded-lg mb-6">
          <p className="text-sm text-blue-600 dark:text-blue-400">
            Vous avez été invité à rejoindre SUNDEALS ! 
            Inscrivez-vous pour recevoir 50 SUNCOINS de bienvenue.
          </p>
        </div>
      )}

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <AuthForm initialMode="signup" referralCode={referralCode} />
      </div>
    </div>
  );
}

export default SignupPage;