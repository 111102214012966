import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 mt-auto">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="text-sm text-gray-500 dark:text-gray-400">
            © {new Date().getFullYear()} SUNDEALS - Tous droits réservés
          </div>
          
          <nav className="flex flex-wrap justify-center gap-4 text-sm text-gray-500 dark:text-gray-400">
            <Link 
              to="/mentions-legales" 
              className="hover:text-gray-700 dark:hover:text-gray-300"
            >
              Mentions légales
            </Link>
            <Link 
              to="/cgu" 
              className="hover:text-gray-700 dark:hover:text-gray-300"
            >
              CGU
            </Link>
            <Link 
              to="/confidentialite" 
              className="hover:text-gray-700 dark:hover:text-gray-300"
            >
              Politique de confidentialité
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
}