import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeals } from '../lib/stores/deals';
import { useAuth } from '../lib/auth';
import { useNotify } from '../hooks/useNotify';
import { DealForm } from '../components/deals/DealForm';
import { LoadingOverlay } from '../components/ui/LoadingOverlay';

function NewDealPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { addDeal } = useDeals();
  const { user } = useAuth();
  const notify = useNotify();

  const handleSubmit = async (data: any) => {
    if (!user) {
      notify.warning('Vous devez être connecté pour publier un bon plan');
      return;
    }

    try {
      setIsSubmitting(true);

      // Préparer les données du deal
      const dealData = {
        title: data.title.trim(),
        description: data.description.trim(),
        category: data.category,
        price: data.price ? Number(data.price) || null : null,
        normalPrice: data.normalPrice ? Number(data.normalPrice) || null : null,
        promoCode: data.promoCode?.trim() || null,
        expirationDate: data.expirationDate ? new Date(data.expirationDate) : null,
        imageUrl: data.imageUrl || null,
        externalLink: data.externalLink?.trim() || null,
        merchant: data.merchant?.trim() || null,
        authorId: user.id,
        location: data.location ? {
          latitude: Number(data.location.latitude),
          longitude: Number(data.location.longitude),
          address: data.location.address
        } : null,
        status: 'active'
      };

      const dealId = await addDeal(dealData);
      notify.success('Votre bon plan a été publié avec succès !');
      navigate(`/deals/${dealId}`);
    } catch (error) {
      console.error('Error publishing deal:', error);
      notify.error('Une erreur est survenue lors de la publication');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto px-4">
      {isSubmitting && <LoadingOverlay message="Publication en cours..." />}
      
      <h1 className="text-2xl font-bold mb-6">Publier un nouveau bon plan</h1>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <DealForm 
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
        />
      </div>
    </div>
  );
}

export default NewDealPage;