import { useEffect, useState, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import { useDeals } from '../lib/stores/deals';
import { useGeolocation } from '../hooks/useGeolocation';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { ErrorMessage } from '../components/ui/ErrorMessage';
import { Button } from '../components/ui/Button';
import { MapPin } from 'lucide-react';

// Icons configuration
const userIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const dealIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

function NearbyDealsPage() {
  const { deals, loading, error, initializeRealtimeDeals } = useDeals();
  const { position, error: geoError, getPosition } = useGeolocation();
  const [radius, setRadius] = useState(5); // km

  useEffect(() => {
    const unsubscribe = initializeRealtimeDeals();
    getPosition();
    return () => unsubscribe();
  }, [initializeRealtimeDeals, getPosition]);

  // Filter deals with valid location data
  const dealsWithLocation = deals.filter(deal => 
    deal.location?.latitude && 
    deal.location?.longitude && 
    deal.status === 'active'
  );

  const calculateDistance = useCallback((lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  }, []);

  // Filter deals within radius
  const nearbyDeals = position ? dealsWithLocation.filter(deal => {
    const distance = calculateDistance(
      position.latitude,
      position.longitude,
      deal.location!.latitude,
      deal.location!.longitude
    );
    return distance <= radius;
  }) : [];

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || geoError) {
    return (
      <ErrorMessage 
        message={error || geoError || 'Une erreur est survenue'} 
        onRetry={getPosition}
      />
    );
  }

  if (!position) {
    return (
      <div className="flex flex-col items-center justify-center p-8">
        <MapPin className="w-12 h-12 text-blue-500 mb-4" />
        <h2 className="text-xl font-bold mb-4">Localisation nécessaire</h2>
        <p className="text-gray-600 dark:text-gray-400 mb-4 text-center">
          Pour voir les bons plans autour de vous, nous avons besoin de votre position.
        </p>
        <Button onClick={getPosition}>
          Activer la localisation
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Contrôles de la carte - maintenant au-dessus de la carte */}
      <div className="relative z-20 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-bold">Bons plans autour de moi</h1>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500">
              {nearbyDeals.length} bon{nearbyDeals.length > 1 ? 's' : ''} plan{nearbyDeals.length > 1 ? 's' : ''} à proximité
            </span>
            <label className="text-sm">Rayon :</label>
            <select 
              value={radius} 
              onChange={(e) => setRadius(Number(e.target.value))}
              className="rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            >
              <option value="5">5 km</option>
              <option value="10">10 km</option>
              <option value="20">20 km</option>
              <option value="50">50 km</option>
            </select>
          </div>
        </div>
      </div>

      {/* Conteneur de la carte avec z-index inférieur */}
      <div className="relative z-10 h-[600px] rounded-lg overflow-hidden shadow-lg border border-gray-200 dark:border-gray-700">
        <MapContainer
          center={[position.latitude, position.longitude]}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          
          <Marker 
            position={[position.latitude, position.longitude]}
            icon={userIcon}
          >
            <Popup>
              <div className="font-medium">Vous êtes ici</div>
            </Popup>
          </Marker>

          {nearbyDeals.map(deal => (
            <Marker 
              key={deal.id} 
              position={[deal.location!.latitude, deal.location!.longitude]}
              icon={dealIcon}
            >
              <Popup>
                <div className="p-2 max-w-xs">
                  <h3 className="font-bold mb-2">{deal.title}</h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-2 line-clamp-2">
                    {deal.description}
                  </p>
                  {deal.location?.address && (
                    <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                      {deal.location.address}
                    </p>
                  )}
                  <Button 
                    variant="primary" 
                    size="sm" 
                    className="w-full mt-2"
                    onClick={() => window.location.href = `/deals/${deal.id}`}
                  >
                    Voir le détail
                  </Button>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>

      {nearbyDeals.length === 0 && (
        <div className="relative z-20 text-center py-8 bg-white dark:bg-gray-800 rounded-lg shadow-md">
          <p className="text-gray-500 dark:text-gray-400">
            Aucun bon plan trouvé dans un rayon de {radius} km
          </p>
          <Button
            variant="outline"
            className="mt-4"
            onClick={() => setRadius(prev => Math.min(prev * 2, 50))}
          >
            Élargir la zone de recherche
          </Button>
        </div>
      )}
    </div>
  );
}

export default NearbyDealsPage;