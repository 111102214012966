import { initializeMessaging } from '../firebase/config';
import { checkVapidConfig } from './vapid';
import { debugNotifications } from './debug';

export async function initializeNotifications() {
  try {
    // Lancer le diagnostic
    await debugNotifications();

    // Vérifier la configuration VAPID
    const vapidConfigValid = await checkVapidConfig();
    if (!vapidConfigValid) {
      throw new Error('Configuration VAPID invalide');
    }

    // Vérifier le support des notifications
    if (!('Notification' in window)) {
      throw new Error('Ce navigateur ne supporte pas les notifications');
    }

    // Demander la permission si nécessaire
    if (Notification.permission === 'default') {
      const permission = await Notification.requestPermission();
      console.log('Permission notification:', permission);
      if (permission !== 'granted') {
        throw new Error('Permission refusée pour les notifications');
      }
    }

    // Initialiser Firebase Messaging
    const messaging = await initializeMessaging();
    if (!messaging) {
      throw new Error('Impossible d\'initialiser Firebase Messaging');
    }

    console.log('✅ Notifications initialisées avec succès');
    return { success: true, messaging };
  } catch (error) {
    console.error('❌ Erreur d\'initialisation des notifications:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Une erreur est survenue'
    };
  }
}