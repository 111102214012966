import { useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useRewards } from '../../lib/rewards/store';
import { LoadingSpinner } from '../ui/LoadingSpinner';
import { toDate } from '../../lib/utils/date';

interface RewardsHistoryProps {
  userId: string;
  limit?: number;
}

export function RewardsHistory({ userId, limit }: RewardsHistoryProps) {
  const { rewards, loading, error, fetchRewards } = useRewards();

  useEffect(() => {
    fetchRewards(userId);
  }, [userId, fetchRewards]);

  if (loading) return <LoadingSpinner />;
  if (error) return null;
  if (!rewards?.history?.length) return null;

  const historyToShow = limit 
    ? rewards.history.slice(0, limit)
    : rewards.history;

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Historique des récompenses</h3>
      
      <div className="space-y-2">
        {historyToShow.map((item) => (
          <div 
            key={item.id}
            className="flex items-center justify-between p-3 bg-white dark:bg-gray-800 rounded-lg shadow-sm"
          >
            <div>
              <p className="font-medium">{item.reason}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {formatDistanceToNow(toDate(item.timestamp), { 
                  addSuffix: true,
                  locale: fr 
                })}
              </p>
            </div>
            <span className={`font-bold ${
              item.amount > 0 ? 'text-green-500' : 'text-red-500'
            }`}>
              {item.amount > 0 ? '+' : ''}{item.amount}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}