import { create } from 'zustand';
import { Post, PostComment } from '../types';
import { collection, addDoc, serverTimestamp, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from './firebase/config';

interface PostsState {
  posts: Post[];
  loading: boolean;
  error: string | null;
  addPost: (post: Omit<Post, 'id' | 'createdAt'>) => Promise<string>;
  deletePost: (postId: string) => void;
  likePost: (postId: string) => void;
  unlikePost: (postId: string) => void;
  addComment: (postId: string) => void;
  addPostComment: (postId: string, comment: PostComment) => void;
  deletePostComment: (postId: string, commentId: string) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  initializeRealtimePosts: () => () => void;
}

export const usePosts = create<PostsState>((set, get) => ({
  posts: [],
  loading: false,
  error: null,
  addPost: async (post) => {
    try {
      set({ loading: true, error: null });
      const docRef = await addDoc(collection(db, 'posts'), {
        ...post,
        createdAt: serverTimestamp(),
        likes: 0,
        comments: 0,
        postComments: []
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding post:', error);
      set({ error: 'Erreur lors de la publication' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },
  deletePost: (postId) =>
    set((state) => ({
      posts: state.posts.filter((post) => post.id !== postId),
    })),
  likePost: (postId) =>
    set((state) => ({
      posts: state.posts.map((post) =>
        post.id === postId ? { ...post, likes: post.likes + 1 } : post
      ),
    })),
  unlikePost: (postId) =>
    set((state) => ({
      posts: state.posts.map((post) =>
        post.id === postId ? { ...post, likes: Math.max(0, post.likes - 1) } : post
      ),
    })),
  addComment: (postId) =>
    set((state) => ({
      posts: state.posts.map((post) =>
        post.id === postId ? { ...post, comments: post.comments + 1 } : post
      ),
    })),
  addPostComment: (postId, comment) =>
    set((state) => ({
      posts: state.posts.map((post) =>
        post.id === postId
          ? {
              ...post,
              comments: post.comments + 1,
              postComments: [...(post.postComments || []), comment],
            }
          : post
      ),
    })),
  deletePostComment: (postId, commentId) =>
    set((state) => ({
      posts: state.posts.map((post) =>
        post.id === postId
          ? {
              ...post,
              comments: Math.max(0, post.comments - 1),
              postComments: post.postComments?.filter((c) => c.id !== commentId) || [],
            }
          : post
      ),
    })),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  initializeRealtimePosts: () => {
    const q = query(collection(db, 'posts'), orderBy('createdAt', 'desc'));
    
    return onSnapshot(q, 
      (snapshot) => {
        const posts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        })) as Post[];
        set({ posts, loading: false });
      },
      (error) => {
        console.error('Posts subscription error:', error);
        set({ error: 'Erreur lors du chargement des publications', loading: false });
      }
    );
  }
}));