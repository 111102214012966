import { useCallback } from 'react';
import { useInteractionsStore } from '../lib/stores/interactions';
import { useNotify } from './useNotify';
import { useAuth } from '../lib/auth';

export function useInteractions() {
  const { loading, likeDeal: storeLikeDeal, dislikeDeal: storeDislikeDeal } = useInteractionsStore();
  const notify = useNotify();
  const { user } = useAuth();

  const likeDeal = useCallback(async (dealId: string, userId: string) => {
    if (!user) {
      notify.warning('Connectez-vous pour voter');
      return;
    }

    try {
      await storeLikeDeal(dealId, userId);
      notify.success('Vote enregistré !');
    } catch (error) {
      notify.error('Erreur lors de l\'interaction');
    }
  }, [user, notify, storeLikeDeal]);

  const dislikeDeal = useCallback(async (dealId: string, userId: string) => {
    if (!user) {
      notify.warning('Connectez-vous pour voter');
      return;
    }

    try {
      await storeDislikeDeal(dealId, userId);
      notify.success('Vote enregistré !');
    } catch (error) {
      notify.error('Erreur lors de l\'interaction');
    }
  }, [user, notify, storeDislikeDeal]);

  return {
    loading,
    likeDeal,
    dislikeDeal
  };
}