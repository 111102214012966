import { create } from 'zustand';
import { User } from '../types';

interface UserProfile extends User {
  followers: string[];
  following: string[];
  reputation: number;
  dealsCount: number;
  commentsCount: number;
}

interface SocialState {
  profiles: Record<string, UserProfile>;
  loading: boolean;
  error: string | null;
  followUser: (userId: string, targetId: string) => void;
  unfollowUser: (userId: string, targetId: string) => void;
  updateReputation: (userId: string, amount: number) => void;
  getProfile: (userId: string) => UserProfile | null;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
}

export const useSocial = create<SocialState>((set, get) => ({
  profiles: {},
  loading: false,
  error: null,
  followUser: (userId, targetId) =>
    set((state) => {
      const profiles = { ...state.profiles };
      if (profiles[userId] && profiles[targetId]) {
        profiles[userId].following.push(targetId);
        profiles[targetId].followers.push(userId);
      }
      return { profiles };
    }),
  unfollowUser: (userId, targetId) =>
    set((state) => {
      const profiles = { ...state.profiles };
      if (profiles[userId] && profiles[targetId]) {
        profiles[userId].following = profiles[userId].following.filter(
          (id) => id !== targetId
        );
        profiles[targetId].followers = profiles[targetId].followers.filter(
          (id) => id !== userId
        );
      }
      return { profiles };
    }),
  updateReputation: (userId, amount) =>
    set((state) => {
      const profiles = { ...state.profiles };
      if (profiles[userId]) {
        profiles[userId].reputation += amount;
      }
      return { profiles };
    }),
  getProfile: (userId) => get().profiles[userId] || null,
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
}));