import { useState } from 'react';
import { Deal } from '../../types';
import { Search } from 'lucide-react';
import { Button } from '../ui/Button';

interface DealPickerProps {
  deals: Deal[];
  selectedDealId?: string;
  onSelect: (dealId: string) => void;
  onClose: () => void;
}

export function DealPicker({ deals, selectedDealId, onSelect, onClose }: DealPickerProps) {
  const [searchQuery, setSearchQuery] = useState('');

  // Filter deals based on search query
  const filteredDeals = deals.filter(deal => 
    deal.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    deal.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="border rounded-lg p-4 dark:border-gray-600">
      <div className="flex items-center justify-between mb-4">
        <h4 className="font-medium">Sélectionner un bon plan</h4>
        <Button variant="ghost" size="sm" onClick={onClose}>
          Annuler
        </Button>
      </div>

      <div className="relative mb-4">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Rechercher un bon plan..."
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
        />
      </div>

      <div className="space-y-2 max-h-60 overflow-y-auto">
        {filteredDeals.length > 0 ? (
          filteredDeals.map((deal) => (
            <label
              key={deal.id}
              className={`flex items-center space-x-2 p-3 rounded-lg cursor-pointer transition-colors ${
                selectedDealId === deal.id
                  ? 'bg-blue-50 dark:bg-blue-900/20'
                  : 'hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <input
                type="radio"
                name="deal"
                value={deal.id}
                checked={selectedDealId === deal.id}
                onChange={() => onSelect(deal.id)}
                className="text-blue-600"
              />
              <div className="flex-1">
                <p className="font-medium text-gray-900 dark:text-gray-100">{deal.title}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-1">
                  {deal.description}
                </p>
              </div>
            </label>
          ))
        ) : (
          <div className="text-center py-4 text-gray-500 dark:text-gray-400">
            {searchQuery ? 'Aucun bon plan trouvé' : 'Aucun bon plan disponible'}
          </div>
        )}
      </div>
    </div>
  );
}