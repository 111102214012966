import { User } from 'lucide-react';
import { useAuth } from '../../lib/auth';
import { useSocial } from '../../lib/social';
import { Button } from '../ui/Button';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface UserProfileProps {
  userId: string;
}

export function UserProfile({ userId }: UserProfileProps) {
  const { user } = useAuth();
  const { getProfile, followUser, unfollowUser, loading } = useSocial();
  const profile = getProfile(userId);

  if (loading) return <LoadingSpinner />;
  if (!profile) return null;

  const isFollowing = user && profile.followers.includes(user.id);

  const handleFollowToggle = () => {
    if (!user) return;
    if (isFollowing) {
      unfollowUser(user.id, userId);
    } else {
      followUser(user.id, userId);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <div className="flex items-center space-x-4">
        {profile.avatarUrl ? (
          <img
            src={profile.avatarUrl}
            alt={profile.username}
            className="w-16 h-16 rounded-full"
          />
        ) : (
          <div className="w-16 h-16 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
            <User className="w-8 h-8 text-gray-500 dark:text-gray-400" />
          </div>
        )}
        
        <div className="flex-1">
          <h2 className="text-xl font-bold">{profile.username}</h2>
          <p className="text-gray-500 dark:text-gray-400">
            Membre depuis {new Date(profile.createdAt).toLocaleDateString()}
          </p>
        </div>

        {user && user.id !== userId && (
          <Button
            variant={isFollowing ? 'outline' : 'primary'}
            onClick={handleFollowToggle}
          >
            {isFollowing ? 'Ne plus suivre' : 'Suivre'}
          </Button>
        )}
      </div>

      <div className="grid grid-cols-3 gap-4 mt-6 text-center">
        <div>
          <div className="text-2xl font-bold">{profile.reputation}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">Réputation</div>
        </div>
        <div>
          <div className="text-2xl font-bold">{profile.followers.length}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">Abonnés</div>
        </div>
        <div>
          <div className="text-2xl font-bold">{profile.dealsCount}</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">Bons plans</div>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <h3 className="font-semibold">Activité récente</h3>
        <div className="space-y-2">
          <div className="text-sm text-gray-600 dark:text-gray-300">
            A publié {profile.dealsCount} bons plans
          </div>
          <div className="text-sm text-gray-600 dark:text-gray-300">
            A commenté {profile.commentsCount} fois
          </div>
        </div>
      </div>
    </div>
  );
}