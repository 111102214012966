import { useCallback } from 'react';
import { useNotifications, NotificationType } from '../lib/notifications';

export function useNotify() {
  const { addNotification } = useNotifications();

  const notify = useCallback((message: string, type: NotificationType = 'info') => {
    addNotification({ message, type });
  }, [addNotification]);

  return {
    success: useCallback((message: string) => notify(message, 'success'), [notify]),
    error: useCallback((message: string) => notify(message, 'error'), [notify]),
    warning: useCallback((message: string) => notify(message, 'warning'), [notify]),
    info: useCallback((message: string) => notify(message, 'info'), [notify]),
  };
}