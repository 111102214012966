import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Sun, Moon, Menu, X, PlusCircle, MapPin, Shield, Info } from 'lucide-react';
import { Button } from '../ui/Button';
import { AuthModal } from '../auth/AuthModal';
import { MobileMenu } from './MobileMenu';
import { InstallButton } from '../pwa/InstallButton';
import { useAuth } from '../../lib/auth';
import { useTheme } from '../../lib/theme';
import { NotificationCenter } from '../notifications/NotificationCenter';
import { KeyboardShortcuts } from '../ui/KeyboardShortcuts';
import { ProfileMenu } from './ProfileMenu';

export function Header() {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const { isDark, toggle } = useTheme();

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-white dark:bg-gray-800 dark:border-gray-700">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-xl font-bold">
              SUNDEALS
            </Link>
          </div>

          <nav className="hidden lg:flex items-center space-x-4">
            {isAuthenticated && (
              <Link
                to="/nouveau"
                className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
              >
                <PlusCircle className="w-5 h-5" />
                <span>Publier</span>
              </Link>
            )}
            <Link
              to="/commu"
              className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              Communauté
            </Link>
            <Link
              to="/autour-de-moi"
              className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <MapPin className="w-5 h-5" />
              <span>Autour de moi</span>
            </Link>
            <Link
              to="/a-propos"
              className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
            >
              <Info className="w-5 h-5" />
              <span>À propos</span>
            </Link>
            {user?.role === 'admin' && (
              <Link
                to="/admin"
                className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
              >
                <Shield className="w-5 h-5" />
                <span>Admin</span>
              </Link>
            )}
          </nav>

          <div className="flex items-center space-x-4">
            <InstallButton />
            {isAuthenticated && <NotificationCenter />}
            <KeyboardShortcuts />
            
            <Button
              variant="ghost"
              size="sm"
              onClick={toggle}
              className="hidden sm:flex"
            >
              {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
            </Button>

            {isAuthenticated ? (
              <ProfileMenu />
            ) : (
              <Button
                variant="primary"
                size="sm"
                onClick={() => setIsAuthModalOpen(true)}
                className="hidden lg:flex"
              >
                Connexion
              </Button>
            )}

            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="lg:hidden"
              aria-label="Menu"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </Button>
          </div>
        </div>
      </div>

      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        onAuthClick={() => setIsAuthModalOpen(true)}
      />

      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
    </header>
  );
}