import { useNotify } from '../../hooks/useNotify';

interface ShareOptions {
  title: string;
  text?: string;
  url: string;
}

export async function shareContent(options: ShareOptions) {
  const notify = useNotify();

  try {
    if (navigator.share) {
      await navigator.share(options);
      notify.success('Contenu partagé avec succès !');
    } else {
      await navigator.clipboard.writeText(options.url);
      notify.success('Lien copié dans le presse-papier !');
    }
  } catch (error) {
    if ((error as Error).name !== 'AbortError') {
      notify.error('Erreur lors du partage');
    }
  }
}