import { useEffect } from 'react';
import { useComments } from '../../lib/comments/store';
import { useAuth } from '../../lib/auth';
import { CommentList } from './CommentList';
import { CommentForm } from './CommentForm';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface CommentSectionProps {
  dealId: string;
}

export function CommentSection({ dealId }: CommentSectionProps) {
  const { user } = useAuth();
  const { comments, loading, error, addComment, deleteComment, subscribeToComments } = useComments();

  useEffect(() => {
    const unsubscribe = subscribeToComments(dealId);
    return () => unsubscribe();
  }, [dealId, subscribeToComments]);

  const handleSubmit = async (data: { text: string }) => {
    if (!user) return;
    await addComment(dealId, user.id, data.text);
  };

  if (loading && !comments[dealId]) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-red-500 dark:text-red-400 text-center py-4">
        {error}
      </div>
    );
  }

  const dealComments = comments[dealId] || [];

  return (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold">
        Commentaires ({dealComments.length})
      </h2>

      {user ? (
        <CommentForm
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      ) : (
        <p className="text-gray-500 dark:text-gray-400">
          Connectez-vous pour commenter
        </p>
      )}

      <CommentList
        comments={dealComments}
        onDelete={deleteComment}
      />
    </div>
  );
}