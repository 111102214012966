import { useState } from 'react';
import { MessageSquare, Users, TrendingUp } from 'lucide-react';
import { CommunityFeed } from '../components/community/CommunityFeed';
import { PopularUsers } from '../components/community/PopularUsers';
import { TrendingTopics } from '../components/community/TrendingTopics';
import { Button } from '../components/ui/Button';

type Tab = 'feed' | 'users' | 'trending';

function CommunityPage() {
  const [activeTab, setActiveTab] = useState<Tab>('feed');

  return (
    <div className="max-w-7xl mx-auto px-4">
      <h1 className="text-2xl font-bold mb-6">Communauté SUNDEALS</h1>

      {/* Mobile Tab Navigation */}
      <div className="flex overflow-x-auto scrollbar-hide space-x-2 md:space-x-4 mb-6 -mx-4 px-4 md:mx-0 md:px-0">
        <Button
          variant={activeTab === 'feed' ? 'primary' : 'ghost'}
          onClick={() => setActiveTab('feed')}
          className="flex items-center space-x-2 whitespace-nowrap flex-shrink-0"
          size="sm"
        >
          <MessageSquare className="h-4 w-4" />
          <span>Discussions</span>
        </Button>
        
        <Button
          variant={activeTab === 'users' ? 'primary' : 'ghost'}
          onClick={() => setActiveTab('users')}
          className="flex items-center space-x-2 whitespace-nowrap flex-shrink-0"
          size="sm"
        >
          <Users className="h-4 w-4" />
          <span>Membres actifs</span>
        </Button>

        <Button
          variant={activeTab === 'trending' ? 'primary' : 'ghost'}
          onClick={() => setActiveTab('trending')}
          className="flex items-center space-x-2 whitespace-nowrap flex-shrink-0"
          size="sm"
        >
          <TrendingUp className="h-4 w-4" />
          <span>Tendances</span>
        </Button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {activeTab === 'feed' && <CommunityFeed />}
          {activeTab === 'users' && <PopularUsers />}
          {activeTab === 'trending' && <TrendingTopics />}
        </div>

        {/* Sidebar - Hidden on mobile */}
        <div className="hidden lg:block space-y-6">
          <PopularUsers compact />
          <TrendingTopics compact />
        </div>
      </div>

      {/* Mobile Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-2 lg:hidden">
        <div className="flex justify-around max-w-md mx-auto">
          <button
            onClick={() => setActiveTab('feed')}
            className={`flex flex-col items-center p-2 ${
              activeTab === 'feed' ? 'text-blue-600 dark:text-blue-400' : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            <MessageSquare className="h-5 w-5" />
            <span className="text-xs mt-1">Discussions</span>
          </button>
          <button
            onClick={() => setActiveTab('users')}
            className={`flex flex-col items-center p-2 ${
              activeTab === 'users' ? 'text-blue-600 dark:text-blue-400' : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            <Users className="h-5 w-5" />
            <span className="text-xs mt-1">Membres</span>
          </button>
          <button
            onClick={() => setActiveTab('trending')}
            className={`flex flex-col items-center p-2 ${
              activeTab === 'trending' ? 'text-blue-600 dark:text-blue-400' : 'text-gray-600 dark:text-gray-400'
            }`}
          >
            <TrendingUp className="h-5 w-5" />
            <span className="text-xs mt-1">Tendances</span>
          </button>
        </div>
      </div>

      {/* Bottom spacing for mobile navigation */}
      <div className="h-16 lg:hidden" />
    </div>
  );
}

export default CommunityPage;