import { useState, useCallback } from 'react';

interface Position {
  latitude: number;
  longitude: number;
}

export function useGeolocation() {
  const [position, setPosition] = useState<Position | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const getPosition = useCallback(async () => {
    if (!navigator.geolocation) {
      setError('La géolocalisation n\'est pas supportée par votre navigateur');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const pos = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });

      setPosition({
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude
      });
    } catch (err) {
      setError('Impossible d\'obtenir votre position');
      console.error('Geolocation error:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    position,
    error,
    loading,
    getPosition
  };
}