import { create } from 'zustand';
import { collection, query, orderBy, limit, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase/config';
import { User } from '../../types';

interface ActiveUser {
  user: User;
  dealsCount: number;
  reputation: number;
}

interface CommunityState {
  activeUsers: ActiveUser[];
  trendingTopics: Array<{
    id: string;
    title: string;
    postsCount: number;
    commentsCount: number;
  }>;
  loading: boolean;
  error: string | null;
  fetchActiveUsers: () => Promise<void>;
  fetchTrendingTopics: () => Promise<void>;
}

export const useCommunity = create<CommunityState>((set) => ({
  activeUsers: [],
  trendingTopics: [],
  loading: false,
  error: null,

  fetchActiveUsers: async () => {
    try {
      set({ loading: true, error: null });
      
      // Get users with most deals
      const usersQuery = query(
        collection(db, 'users'),
        orderBy('dealsCount', 'desc'),
        limit(10)
      );
      
      const snapshot = await getDocs(usersQuery);
      const users = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const userData = doc.data();
          
          // Calculate reputation from deals
          const dealsQuery = query(
            collection(db, 'deals'),
            where('authorId', '==', doc.id)
          );
          const dealsSnapshot = await getDocs(dealsQuery);
          const reputation = dealsSnapshot.docs.reduce((total, dealDoc) => {
            const dealData = dealDoc.data();
            return total + ((dealData.likes || 0) - (dealData.dislikes || 0));
          }, 0);

          return {
            user: {
              id: doc.id,
              ...userData,
              createdAt: userData.createdAt?.toDate() || new Date()
            } as User,
            dealsCount: userData.dealsCount || 0,
            reputation
          };
        })
      );

      // Sort by reputation
      const sortedUsers = users.sort((a, b) => b.reputation - a.reputation);

      set({ activeUsers: sortedUsers, loading: false });
    } catch (error) {
      console.error('Error fetching active users:', error);
      set({ 
        error: 'Erreur lors du chargement des utilisateurs actifs', 
        loading: false 
      });
    }
  },

  fetchTrendingTopics: async () => {
    try {
      set({ loading: true, error: null });
      
      const dealsQuery = query(
        collection(db, 'deals'),
        orderBy('createdAt', 'desc'),
        limit(100)
      );
      
      const snapshot = await getDocs(dealsQuery);
      const categories = snapshot.docs.reduce((acc, doc) => {
        const category = doc.data().category;
        if (!acc[category]) {
          acc[category] = {
            id: category,
            title: category === 'deal' ? 'Bons Plans' : 'Codes Promos',
            postsCount: 0,
            commentsCount: 0
          };
        }
        acc[category].postsCount++;
        acc[category].commentsCount += doc.data().comments || 0;
        return acc;
      }, {} as Record<string, any>);

      const trends = Object.values(categories)
        .sort((a, b) => b.postsCount - a.postsCount)
        .slice(0, 5);

      set({ trendingTopics: trends, loading: false });
    } catch (error) {
      console.error('Error fetching trending topics:', error);
      set({ 
        error: 'Erreur lors du chargement des tendances', 
        loading: false 
      });
    }
  }
}));