import { useEffect, useCallback } from 'react';
import { AlertCircle, CheckCircle, Info, X, XCircle } from 'lucide-react';
import { clsx } from 'clsx';
import { NotificationType } from '../../lib/notifications';

interface ToastProps {
  type: NotificationType;
  message: string;
  onClose: () => void;
}

const icons = {
  success: CheckCircle,
  error: XCircle,
  warning: AlertCircle,
  info: Info,
};

const styles = {
  success: 'bg-green-50 text-green-800 dark:bg-green-900/50 dark:text-green-300',
  error: 'bg-red-50 text-red-800 dark:bg-red-900/50 dark:text-red-300',
  warning: 'bg-yellow-50 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-300',
  info: 'bg-blue-50 text-blue-800 dark:bg-blue-900/50 dark:text-blue-300',
};

export function Toast({ type, message, onClose }: ToastProps) {
  const Icon = icons[type];

  useEffect(() => {
    const timer = setTimeout(onClose, 5000);
    return () => clearTimeout(timer);
  }, [onClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div
      className={clsx(
        'flex items-center p-4 mb-3 rounded-lg shadow-lg',
        'transform transition-all duration-300 ease-in-out',
        styles[type]
      )}
      role="alert"
    >
      <Icon className="h-5 w-5 mr-3" />
      <p className="mr-3 font-medium">{message}</p>
      <button
        onClick={handleClose}
        className="ml-auto bg-transparent text-current hover:opacity-75"
        aria-label="Fermer"
      >
        <X className="h-5 w-5" />
      </button>
    </div>
  );
}