import { NotificationGroup } from '../../lib/notifications/groups';
import { NotificationItem } from './NotificationItem';

interface NotificationGroupItemProps {
  group: NotificationGroup;
  onClose: () => void;
}

export function NotificationGroupItem({ group, onClose }: NotificationGroupItemProps) {
  return (
    <div className="border-b dark:border-gray-700 last:border-0">
      <div className="px-4 py-2 bg-gray-50 dark:bg-gray-800/50">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            {group.title}
          </h3>
          {group.unreadCount > 0 && (
            <span className="text-xs font-medium text-blue-600 dark:text-blue-400">
              {group.unreadCount} non lu{group.unreadCount > 1 ? 's' : ''}
            </span>
          )}
        </div>
      </div>
      <div className="divide-y dark:divide-gray-700">
        {group.notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onClose={onClose}
          />
        ))}
      </div>
    </div>
  );
}