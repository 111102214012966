import { useRef, useState } from 'react';
import { Camera, X } from 'lucide-react';
import { Button } from '../ui/Button';
import { uploadUserAvatar } from '../../lib/firebase/services/users';
import { useNotify } from '../../hooks/useNotify';

interface AvatarUploadProps {
  userId: string;
  currentAvatar?: string;
  onUpload: (url: string) => void;
}

export function AvatarUpload({ userId, currentAvatar, onUpload }: AvatarUploadProps) {
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const notify = useNotify();

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Vérifier le type et la taille du fichier
    if (!file.type.startsWith('image/')) {
      notify.error('Veuillez sélectionner une image');
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      notify.error('L\'image ne doit pas dépasser 2 Mo');
      return;
    }

    try {
      setUploading(true);
      const url = await uploadUserAvatar(userId, file);
      onUpload(url);
      notify.success('Avatar mis à jour avec succès');
    } catch (error) {
      notify.error('Erreur lors du téléchargement de l\'avatar');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="relative">
      {currentAvatar ? (
        <div className="relative inline-block">
          <img
            src={currentAvatar}
            alt="Avatar"
            className="w-24 h-24 rounded-full object-cover"
          />
          <Button
            variant="ghost"
            size="sm"
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading}
            className="absolute bottom-0 right-0 bg-white dark:bg-gray-800 rounded-full p-1 shadow-md"
          >
            <Camera className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        <Button
          variant="outline"
          onClick={() => fileInputRef.current?.click()}
          disabled={uploading}
          className="w-24 h-24 rounded-full flex items-center justify-center"
        >
          <Camera className="h-6 w-6" />
        </Button>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        className="hidden"
      />
    </div>
  );
}