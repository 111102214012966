import { useState } from 'react';
import { Link } from 'lucide-react';
import { Button } from '../ui/Button';
import { Deal } from '../../types';
import { DealPicker } from './DealPicker';

interface PostFormProps {
  onSubmit: (content: string, dealId?: string) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
  deals: Deal[];
}

export function PostForm({ onSubmit, onCancel, isSubmitting, deals }: PostFormProps) {
  const [content, setContent] = useState('');
  const [showDealPicker, setShowDealPicker] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<string | undefined>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (content.trim()) {
      onSubmit(content, selectedDealId);
      setContent('');
      setSelectedDealId(undefined);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Que souhaitez-vous partager avec la communauté ?"
        className="w-full h-32 p-3 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        disabled={isSubmitting}
      />
      
      {showDealPicker ? (
        <DealPicker
          deals={deals}
          selectedDealId={selectedDealId}
          onSelect={setSelectedDealId}
          onClose={() => setShowDealPicker(false)}
        />
      ) : (
        <Button
          type="button"
          variant="ghost"
          size="sm"
          onClick={() => setShowDealPicker(true)}
          className="flex items-center space-x-2"
          disabled={isSubmitting}
        >
          <Link className="h-4 w-4" />
          <span>Lier un bon plan</span>
        </Button>
      )}

      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="ghost"
          onClick={() => {
            onCancel();
            setContent('');
            setSelectedDealId(undefined);
          }}
          disabled={isSubmitting}
        >
          Annuler
        </Button>
        <Button
          type="submit"
          disabled={!content.trim() || isSubmitting}
        >
          {isSubmitting ? 'Publication...' : 'Publier'}
        </Button>
      </div>
    </form>
  );
}