import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDeals } from '../lib/stores/deals';
import { useAuth } from '../lib/auth';
import { useNotify } from '../hooks/useNotify';
import { Button } from '../components/ui/Button';
import { ArrowLeft, Share2 } from 'lucide-react';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { CommentSection } from '../components/comments/CommentSection';

function DealDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { deals, initializeRealtimeDeals } = useDeals();
  const notify = useNotify();

  useEffect(() => {
    const unsubscribe = initializeRealtimeDeals();
    return () => unsubscribe();
  }, [initializeRealtimeDeals]);

  const deal = id ? deals.find((d) => d.id === id) : null;

  if (!deal && deals.length > 0) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-8 text-center">
        <h1 className="text-2xl font-bold mb-4">Bon plan introuvable</h1>
        <Button variant="outline" onClick={() => navigate('/')}>
          Retour à l'accueil
        </Button>
      </div>
    );
  }

  if (!deal) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-4xl mx-auto px-4">
      <Button
        variant="ghost"
        className="mb-6"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Retour
      </Button>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
        {deal.imageUrl && (
          <img
            src={deal.imageUrl}
            alt={deal.title}
            className="w-full h-64 object-cover"
          />
        )}

        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-bold">{deal.title}</h1>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => {
                if (navigator.share) {
                  navigator.share({
                    title: deal.title,
                    text: deal.description,
                    url: window.location.href,
                  });
                } else {
                  navigator.clipboard.writeText(window.location.href);
                  notify.success('Lien copié dans le presse-papier !');
                }
              }}
            >
              <Share2 className="h-4 w-4 mr-2" />
              Partager
            </Button>
          </div>

          <p className="text-gray-600 dark:text-gray-300 mb-6">{deal.description}</p>

          <div className="border-t dark:border-gray-700 pt-6">
            <CommentSection dealId={deal.id} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DealDetailsPage;