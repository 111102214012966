import { PackageSearch } from 'lucide-react';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { Link } from 'react-router-dom';

interface EmptyStateProps {
  message?: string;
  showCreateButton?: boolean;
}

export function EmptyState({ 
  message = "Aucun bon plan trouvé",
  showCreateButton = true 
}: EmptyStateProps) {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex flex-col items-center justify-center p-8 text-center">
      <PackageSearch className="h-12 w-12 text-gray-400 mb-4" />
      <p className="text-lg font-medium text-gray-900 dark:text-white mb-2">
        {message}
      </p>
      {showCreateButton && isAuthenticated && (
        <Link to="/nouveau">
          <Button variant="primary" className="mt-4">
            Publier un bon plan
          </Button>
        </Link>
      )}
    </div>
  );
}