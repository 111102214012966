import { create } from 'zustand';
import { Deal } from '../../types';
import { dealsService } from '../services/deals';
import { CategoryFilter, SortOption } from '../filters';

interface DealsState {
  deals: Deal[];
  loading: boolean;
  error: string | null;
  addDeal: (deal: Omit<Deal, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  setDeals: (deals: Deal[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  filterDeals: (search: string, category: CategoryFilter, sort: SortOption) => Deal[];
  initializeRealtimeDeals: () => () => void;
}

export const useDeals = create<DealsState>((set, get) => ({
  deals: [],
  loading: true,
  error: null,

  addDeal: async (deal) => {
    try {
      set({ loading: true, error: null });
      const id = await dealsService.createDeal(deal);
      return id;
    } catch (error) {
      console.error('Error creating deal:', error);
      set({ error: 'Erreur lors de la création du bon plan' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  setDeals: (deals) => set({ deals }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  filterDeals: (search, category, sort) => {
    const { deals } = get();
    let filteredDeals = [...deals];

    if (category !== 'all') {
      filteredDeals = filteredDeals.filter(deal => deal.category === category);
    }

    if (search) {
      const searchLower = search.toLowerCase();
      filteredDeals = filteredDeals.filter(
        deal =>
          deal.title.toLowerCase().includes(searchLower) ||
          deal.description.toLowerCase().includes(searchLower)
      );
    }

    switch (sort) {
      case 'popular':
        filteredDeals.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        break;
      case 'controversial':
        filteredDeals.sort((a, b) => 
          Math.abs((b.dislikes || 0) - (b.likes || 0)) - 
          Math.abs((a.dislikes || 0) - (a.likes || 0))
        );
        break;
      case 'recent':
      default:
        filteredDeals.sort((a, b) => 
          (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0)
        );
    }

    return filteredDeals;
  },

  initializeRealtimeDeals: () => {
    set({ loading: true, error: null });
    return dealsService.subscribeToDeals(
      (deals) => {
        set({ deals, loading: false });
      },
      (error) => {
        console.error('Deals subscription error:', error);
        set({ error: error.message, loading: false });
      }
    );
  }
}));