import { useEffect, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { LoadingSpinner } from './components/ui/LoadingSpinner';
import { ErrorBoundary } from './components/ui/ErrorBoundary';
import { useTheme } from './lib/theme';
import { ToastContainer } from './components/ui/ToastContainer';
import { OfflineIndicator } from './components/ui/OfflineIndicator';
import { SyncIndicator } from './components/ui/SyncIndicator';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { AdminRoute } from './components/auth/AdminRoute';

// Pages
import HomePage from './pages/HomePage';
import NewDealPage from './pages/NewDealPage';
import DealDetailsPage from './pages/DealDetailsPage';
import UserProfilePage from './pages/UserProfilePage';
import ProfilePage from './pages/ProfilePage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import CommunityPage from './pages/CommunityPage';
import NearbyDealsPage from './pages/NearbyDealsPage';
import AboutPage from './pages/AboutPage';
import SettingsPage from './pages/SettingsPage';
import SignupPage from './pages/SignupPage';
import CGU from './pages/LegalPages/CGU';
import PrivacyPolicy from './pages/LegalPages/PrivacyPolicy';
import LegalNotice from './pages/LegalPages/LegalNotice';

export default function App() {
  const { isDark } = useTheme();
  const location = useLocation();

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDark);
  }, [isDark]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ErrorBoundary>
      <div 
        className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors flex flex-col"
        role="application"
      >
        <a 
          href="#main-content" 
          className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 focus:z-50 focus:p-4 focus:bg-white dark:focus:bg-gray-800"
        >
          Aller au contenu principal
        </a>
        
        <Header />
        
        <main id="main-content" className="container mx-auto px-4 py-8 flex-grow">
          <ErrorBoundary>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/deals/:id" element={<DealDetailsPage />} />
                <Route path="/users/:userId" element={<UserProfilePage />} />
                <Route path="/commu" element={<CommunityPage />} />
                <Route path="/autour-de-moi" element={<NearbyDealsPage />} />
                <Route path="/a-propos" element={<AboutPage />} />
                <Route path="/cgu" element={<CGU />} />
                <Route path="/confidentialite" element={<PrivacyPolicy />} />
                <Route path="/mentions-legales" element={<LegalNotice />} />
                <Route path="/inscription" element={<SignupPage />} />
                <Route
                  path="/nouveau"
                  element={
                    <PrivateRoute>
                      <NewDealPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/parametres"
                  element={
                    <PrivateRoute>
                      <SettingsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <AdminRoute>
                      <AdminDashboardPage />
                    </AdminRoute>
                  }
                />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </main>
        
        <Footer />
        
        <ToastContainer />
        <OfflineIndicator />
        <SyncIndicator />
      </div>
    </ErrorBoundary>
  );
}