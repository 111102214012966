import { useRef, useState } from 'react';
import { Camera, X } from 'lucide-react';
import { Button } from './Button';
import { UploadService } from '../../lib/services/upload';
import { useNotify } from '../../hooks/useNotify';
import { ProgressBar } from './ProgressBar';

interface ImageUploadProps {
  onUpload: (url: string) => void;
  onError?: (error: Error) => void;
  currentImage?: string;
  className?: string;
  dealId: string;
}

export function ImageUpload({ 
  onUpload, 
  onError, 
  currentImage, 
  className = '',
  dealId 
}: ImageUploadProps) {
  const [preview, setPreview] = useState<string | null>(currentImage || null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadState, setUploadState] = useState<'idle' | 'compressing' | 'uploading' | 'complete' | 'error'>('idle');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const notify = useNotify();

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      setUploadState('idle');
      setUploadProgress(0);

      // Delete previous image if exists
      if (preview) {
        await UploadService.deleteImage(preview);
      }

      const url = await UploadService.uploadDealImage(
        file,
        dealId,
        (progress) => {
          setUploadProgress(progress.progress);
          setUploadState(progress.state);
        }
      );

      setPreview(url);
      onUpload(url);
      notify.success('Image téléchargée avec succès');
    } catch (error) {
      const err = error as Error;
      notify.error(err.message);
      onError?.(err);
      setUploadState('error');
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = async () => {
    if (preview) {
      try {
        await UploadService.deleteImage(preview);
        setPreview(null);
        onUpload('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        notify.success('Image supprimée avec succès');
      } catch (error) {
        notify.error('Erreur lors de la suppression de l\'image');
      }
    }
  };

  return (
    <div className={`relative ${className}`}>
      {preview ? (
        <div className="relative">
          <img
            src={preview}
            alt="Aperçu"
            className="w-full h-48 object-cover rounded-lg"
          />
          <Button
            variant="ghost"
            size="sm"
            onClick={handleRemove}
            className="absolute top-2 right-2 bg-white/80 hover:bg-white/90 rounded-full p-1"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        <Button
          type="button"
          variant="outline"
          onClick={() => fileInputRef.current?.click()}
          disabled={uploading}
          className="w-full h-48 flex flex-col items-center justify-center space-y-2 border-2 border-dashed rounded-lg"
        >
          <Camera className="h-8 w-8" />
          <span>
            {uploading 
              ? uploadState === 'compressing'
                ? 'Compression...'
                : 'Téléchargement...'
              : 'Ajouter une image'
            }
          </span>
          {uploading && (
            <ProgressBar 
              progress={uploadProgress}
              state={uploadState}
              className="w-32"
            />
          )}
        </Button>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png,image/webp"
        onChange={handleFileSelect}
        className="hidden"
      />
    </div>
  );
}