import { usePreferences } from '../../lib/preferences/store';
import { useTheme } from '../../lib/theme';
import { Button } from '../ui/Button';
import { Sun, Moon, Monitor, Bell, Eye, Layout, Globe } from 'lucide-react';

export function PreferencesPanel() {
  const { preferences, setPreference, resetPreferences } = usePreferences();
  const { isDark, setTheme } = useTheme();

  const handleThemeChange = (theme: 'light' | 'dark' | 'system') => {
    if (theme === 'system') {
      const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setTheme(systemDark);
    } else {
      setTheme(theme === 'dark');
    }
    setPreference('theme', theme);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-6">Préférences</h2>

        {/* Thème */}
        <section className="mb-8">
          <h3 className="text-lg font-medium mb-4 flex items-center">
            <Sun className="w-5 h-5 mr-2" />
            Thème
          </h3>
          <div className="grid grid-cols-3 gap-4">
            <Button
              variant={preferences.theme === 'light' ? 'primary' : 'outline'}
              onClick={() => handleThemeChange('light')}
              className="flex items-center justify-center"
            >
              <Sun className="w-4 h-4 mr-2" />
              Clair
            </Button>
            <Button
              variant={preferences.theme === 'dark' ? 'primary' : 'outline'}
              onClick={() => handleThemeChange('dark')}
              className="flex items-center justify-center"
            >
              <Moon className="w-4 h-4 mr-2" />
              Sombre
            </Button>
            <Button
              variant={preferences.theme === 'system' ? 'primary' : 'outline'}
              onClick={() => handleThemeChange('system')}
              className="flex items-center justify-center"
            >
              <Monitor className="w-4 h-4 mr-2" />
              Système
            </Button>
          </div>
        </section>

        {/* Notifications */}
        <section className="mb-8">
          <h3 className="text-lg font-medium mb-4 flex items-center">
            <Bell className="w-5 h-5 mr-2" />
            Notifications
          </h3>
          <div className="space-y-4">
            {Object.entries(preferences.notifications).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <span className="capitalize">{key}</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={value}
                    onChange={(e) =>
                      setPreference('notifications', {
                        ...preferences.notifications,
                        [key]: e.target.checked,
                      })
                    }
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            ))}
          </div>
        </section>

        {/* Confidentialité */}
        <section className="mb-8">
          <h3 className="text-lg font-medium mb-4 flex items-center">
            <Eye className="w-5 h-5 mr-2" />
            Confidentialité
          </h3>
          <div className="space-y-4">
            {Object.entries(preferences.privacy).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <span className="capitalize">{key}</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={value}
                    onChange={(e) =>
                      setPreference('privacy', {
                        ...preferences.privacy,
                        [key]: e.target.checked,
                      })
                    }
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            ))}
          </div>
        </section>

        {/* Affichage */}
        <section className="mb-8">
          <h3 className="text-lg font-medium mb-4 flex items-center">
            <Layout className="w-5 h-5 mr-2" />
            Affichage
          </h3>
          <div className="space-y-4">
            {Object.entries(preferences.display).map(([key, value]) => (
              <div key={key} className="flex items-center justify-between">
                <span className="capitalize">{key}</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={value}
                    onChange={(e) =>
                      setPreference('display', {
                        ...preferences.display,
                        [key]: e.target.checked,
                      })
                    }
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
            ))}
          </div>
        </section>

        <div className="flex justify-end space-x-4">
          <Button variant="outline" onClick={resetPreferences}>
            Réinitialiser
          </Button>
          <Button>
            Enregistrer
          </Button>
        </div>
      </div>
    </div>
  );
}