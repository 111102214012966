import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ThemeState {
  isDark: boolean;
  toggle: () => void;
  setTheme: (isDark: boolean) => void;
}

export const useTheme = create<ThemeState>()(
  persist(
    (set) => ({
      isDark: window.matchMedia('(prefers-color-scheme: dark)').matches,
      toggle: () => set((state) => {
        const newIsDark = !state.isDark;
        document.documentElement.classList.toggle('dark', newIsDark);
        return { isDark: newIsDark };
      }),
      setTheme: (isDark: boolean) => set(() => {
        document.documentElement.classList.toggle('dark', isDark);
        return { isDark };
      }),
    }),
    {
      name: 'theme-storage',
    }
  )
);

// Initialiser le thème au chargement
const savedTheme = JSON.parse(localStorage.getItem('theme-storage') || '{}');
document.documentElement.classList.toggle('dark', savedTheme?.state?.isDark ?? false);