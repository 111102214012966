import { getMessaging, getToken } from 'firebase/messaging';
import { app } from '../firebase/config';

export async function checkVapidConfig() {
  const vapidKey = import.meta.env.VITE_FIREBASE_VAPID_KEY;
  
  console.log('Vérification de la configuration VAPID:');
  console.log('- Clé VAPID présente:', !!vapidKey);
  
  if (!vapidKey) {
    console.error('❌ La clé VAPID n\'est pas définie dans les variables d\'environnement');
    return false;
  }

  try {
    const messaging = getMessaging(app);
    const token = await getToken(messaging, { vapidKey });
    
    console.log('- Token FCM généré:', !!token);
    console.log('✅ Configuration VAPID valide');
    
    return true;
  } catch (error) {
    console.error('❌ Erreur lors de la vérification de la clé VAPID:', error);
    return false;
  }
}