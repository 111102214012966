import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/Button';
import { ImageUpload } from '../ui/ImageUpload';
import { AddressAutocomplete } from '../ui/AddressAutocomplete';
import { Camera, Link as LinkIcon } from 'lucide-react';

const dealSchema = z.object({
  title: z.string()
    .min(5, 'Le titre doit contenir au moins 5 caractères')
    .max(100, 'Le titre ne doit pas dépasser 100 caractères'),
  description: z.string()
    .min(20, 'La description doit contenir au moins 20 caractères')
    .max(2000, 'La description ne doit pas dépasser 2000 caractères'),
  category: z.enum(['deal', 'promo'], {
    required_error: 'Veuillez sélectionner une catégorie',
  }),
  price: z.string().optional(),
  normalPrice: z.string().optional(),
  promoCode: z.string().optional(),
  expirationDate: z.string().optional(),
  imageUrl: z.string().optional(),
  externalLink: z.string().url('URL invalide').optional().or(z.literal('')),
  merchant: z.string().optional(),
  address: z.string().optional(),
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
    address: z.string()
  }).optional()
});

type DealFormInputs = z.infer<typeof dealSchema>;

interface DealFormProps {
  onSubmit: (data: DealFormInputs) => void;
  isLoading?: boolean;
}

export function DealForm({ onSubmit, isLoading }: DealFormProps) {
  const [location, setLocation] = useState<{ latitude: number; longitude: number; address: string } | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DealFormInputs>({
    resolver: zodResolver(dealSchema),
    defaultValues: {
      category: 'deal',
    },
  });

  const category = watch('category');
  const dealId = crypto.randomUUID(); // Generate a unique ID for the deal

  const handleFormSubmit = (data: DealFormInputs) => {
    onSubmit({
      ...data,
      location: location || undefined
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Type d'offre
        </label>
        <div className="grid grid-cols-2 gap-4">
          <label className={`flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer ${
            category === 'deal'
              ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
              : 'border-gray-200 dark:border-gray-700'
          }`}>
            <input
              type="radio"
              value="deal"
              {...register('category')}
              className="sr-only"
            />
            <span>Bon Plan</span>
          </label>
          
          <label className={`flex items-center justify-center p-4 rounded-lg border-2 cursor-pointer ${
            category === 'promo'
              ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
              : 'border-gray-200 dark:border-gray-700'
          }`}>
            <input
              type="radio"
              value="promo"
              {...register('category')}
              className="sr-only"
            />
            <span>Code Promo</span>
          </label>
        </div>
        {errors.category && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {errors.category.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Titre
        </label>
        <input
          {...register('title')}
          className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
          placeholder={category === 'deal' ? "Ex: -50% sur les smartphones Samsung" : "Ex: 20% de réduction sur tout le site"}
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {errors.title.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Description
        </label>
        <textarea
          {...register('description')}
          rows={4}
          className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
          placeholder="Décrivez le bon plan en détail..."
        />
        {errors.description && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {errors.description.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Image
        </label>
        <ImageUpload
          dealId={dealId}
          onUpload={(url) => setValue('imageUrl', url)}
          onError={(error) => setUploadError(error.message)}
          currentImage={watch('imageUrl')}
        />
        {uploadError && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {uploadError}
          </p>
        )}
      </div>

      {category === 'deal' && (
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Prix promotionnel
            </label>
            <input
              {...register('price')}
              type="text"
              className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
              placeholder="Ex: 299.99€"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Prix normal
            </label>
            <input
              {...register('normalPrice')}
              type="text"
              className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
              placeholder="Ex: 599.99€"
            />
          </div>
        </div>
      )}

      {category === 'promo' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Code promo
          </label>
          <input
            {...register('promoCode')}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            placeholder="Ex: SUMMER20"
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Date d'expiration
        </label>
        <input
          {...register('expirationDate')}
          type="date"
          className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Adresse du bon plan
        </label>
        <AddressAutocomplete
          value={watch('address') || ''}
          onChange={(value) => setValue('address', value)}
          onSelect={(suggestion) => {
            setValue('address', suggestion.display_name);
            setLocation({
              latitude: parseFloat(suggestion.lat),
              longitude: parseFloat(suggestion.lon),
              address: suggestion.display_name
            });
          }}
          error={errors.address?.message}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Lien de l'offre (optionnel)
        </label>
        <div className="flex items-center space-x-2">
          <input
            {...register('externalLink')}
            type="url"
            className="flex-1 rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            placeholder="https://example.com/offre"
          />
          <Button type="button" variant="outline" size="sm">
            <LinkIcon className="h-4 w-4" />
          </Button>
        </div>
        {errors.externalLink && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">
            {errors.externalLink.message}
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Marchand
        </label>
        <input
          {...register('merchant')}
          className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
          placeholder="Ex: Amazon, Fnac, etc."
        />
      </div>

      <div className="flex justify-end space-x-4 pt-4">
        <Button
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Publication...' : 'Publier'}
        </Button>
      </div>
    </form>
  );
}