import { useState, useEffect, useRef } from 'react';
import { Bell } from 'lucide-react';
import { useNotificationStore } from '../../lib/notifications/store';
import { Button } from '../ui/Button';
import { NotificationGroupItem } from './NotificationGroupItem';
import { NotificationBadge } from './NotificationBadge';
import { NotificationSound } from './NotificationSound';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { groupNotifications } from '../../lib/notifications/groups';

export function NotificationCenter() {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { notifications, unreadCount, markAllAsRead, clearAll } = useNotificationStore();
  const groups = groupNotifications(notifications);

  useOnClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') setIsOpen(false);
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  return (
    <div className="relative" ref={ref}>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
        className="relative"
        aria-label="Notifications"
      >
        <Bell className="h-5 w-5" />
        <NotificationBadge count={unreadCount} />
      </Button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden z-50">
          <div className="p-4 border-b dark:border-gray-700">
            <div className="flex items-center justify-between">
              <h3 className="font-semibold">Notifications</h3>
              <div className="flex items-center space-x-2">
                {unreadCount > 0 && (
                  <Button variant="ghost" size="sm" onClick={markAllAsRead}>
                    Tout marquer comme lu
                  </Button>
                )}
                {notifications.length > 0 && (
                  <Button variant="ghost" size="sm" onClick={clearAll}>
                    Effacer tout
                  </Button>
                )}
              </div>
            </div>
          </div>

          <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
            {groups.length > 0 ? (
              groups.map((group) => (
                <NotificationGroupItem
                  key={group.id}
                  group={group}
                  onClose={() => setIsOpen(false)}
                />
              ))
            ) : (
              <div className="p-8 text-center text-gray-500">
                <Bell className="h-8 w-8 mx-auto mb-2 opacity-50" />
                <p>Aucune notification</p>
              </div>
            )}
          </div>
        </div>
      )}

      <NotificationSound />
    </div>
  );
}