import { Timestamp } from 'firebase/firestore';

export function toDate(date: Date | Timestamp | string | number | undefined): Date {
  if (!date) return new Date();
  
  if (date instanceof Date) return date;
  
  if (date instanceof Timestamp) {
    return date.toDate();
  }
  
  if (typeof date === 'string' || typeof date === 'number') {
    const parsed = new Date(date);
    return isNaN(parsed.getTime()) ? new Date() : parsed;
  }
  
  return new Date();
}