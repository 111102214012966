import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { User, LogOut, Settings } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/auth';
import { clsx } from 'clsx';

export function ProfileMenu() {
  const { user, logout } = useAuth();

  if (!user) return null;

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
        <img
          src={user.avatarUrl || `https://api.dicebear.com/7.x/avatars/svg?seed=${user.id}`}
          alt="Avatar"
          className="w-8 h-8 rounded-full"
        />
        <span className="hidden md:block">{user.username}</span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white dark:bg-gray-800 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={clsx(
                    'flex items-center px-4 py-2',
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  )}
                >
                  <User className="h-4 w-4 mr-2" />
                  Mon Profil
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/parametres"
                  className={clsx(
                    'flex items-center px-4 py-2',
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  )}
                >
                  <Settings className="h-4 w-4 mr-2" />
                  Paramètres
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => logout()}
                  className={clsx(
                    'flex items-center w-full px-4 py-2',
                    active ? 'bg-gray-100 dark:bg-gray-700' : ''
                  )}
                >
                  <LogOut className="h-4 w-4 mr-2" />
                  Se déconnecter
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}