import { Search, SlidersHorizontal, X } from 'lucide-react';
import { Button } from '../ui/Button';
import { useFilters } from '../../lib/filters';
import { useState } from 'react';

export function SearchFilters() {
  const { search, setSearch, reset } = useFilters();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClearSearch = () => {
    setSearch('');
    setIsExpanded(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setIsExpanded(true)}
            placeholder="Rechercher un bon plan..."
            className="w-full pl-10 pr-10 py-2 rounded-lg border border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          {search && (
            <button
              onClick={handleClearSearch}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center gap-2"
        >
          <SlidersHorizontal className="h-4 w-4" />
          <span className="hidden sm:inline">Filtres</span>
        </Button>
      </div>

      {isExpanded && (
        <div className="absolute top-full left-0 right-0 mt-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-10">
          <AdvancedFilters onClose={() => setIsExpanded(false)} />
        </div>
      )}
    </div>
  );
}

function AdvancedFilters({ onClose }: { onClose: () => void }) {
  const { category, sort, setCategory, setSort, reset } = useFilters();

  const handleReset = () => {
    reset();
    onClose();
  };

  return (
    <div className="space-y-4">
      <div className="grid gap-4 sm:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Catégorie
          </label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value as any)}
            className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 py-2 pl-3 pr-10 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="all">Toutes les catégories</option>
            <option value="deal">Bons Plans</option>
            <option value="promo">Codes Promos</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Trier par
          </label>
          <select
            value={sort}
            onChange={(e) => setSort(e.target.value as any)}
            className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 py-2 pl-3 pr-10 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="recent">Plus récents</option>
            <option value="popular">Plus populaires</option>
            <option value="controversial">Controversés</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end gap-2 pt-2">
        <Button variant="ghost" onClick={handleReset}>
          Réinitialiser
        </Button>
        <Button variant="primary" onClick={onClose}>
          Appliquer
        </Button>
      </div>
    </div>
  );
}