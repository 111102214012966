import { create } from 'zustand';
import { Comment } from '../../types';
import { commentsApi } from './api';
import { onSnapshot } from 'firebase/firestore';

interface CommentsState {
  comments: Record<string, Comment[]>;
  loading: boolean;
  error: string | null;
  addComment: (dealId: string, userId: string, text: string) => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
  setComments: (dealId: string, comments: Comment[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  subscribeToComments: (dealId: string) => () => void;
}

export const useComments = create<CommentsState>((set) => ({
  comments: {},
  loading: false,
  error: null,

  addComment: async (dealId, userId, text) => {
    try {
      set({ loading: true, error: null });
      await commentsApi.addComment(dealId, userId, text);
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Erreur inconnue' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deleteComment: async (commentId) => {
    try {
      set({ loading: true, error: null });
      await commentsApi.deleteComment(commentId);
    } catch (error) {
      set({ error: error instanceof Error ? error.message : 'Erreur inconnue' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  setComments: (dealId, comments) =>
    set((state) => ({
      comments: { ...state.comments, [dealId]: comments },
    })),

  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  subscribeToComments: (dealId) => {
    set({ loading: true, error: null });
    
    const query = commentsApi.getCommentsQuery(dealId);
    
    return onSnapshot(
      query,
      (snapshot) => {
        const comments = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date()
        })) as Comment[];

        set((state) => ({
          comments: { ...state.comments, [dealId]: comments },
          loading: false,
          error: null
        }));
      },
      (error) => {
        console.error('Error in comments subscription:', error);
        set({ 
          error: 'Erreur lors du chargement des commentaires',
          loading: false 
        });
      }
    );
  }
}));