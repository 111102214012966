import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../firebase/config';
import { compressImage } from '../utils/image';

interface UploadProgress {
  progress: number;
  state: 'compressing' | 'uploading' | 'complete' | 'error';
}

export class UploadService {
  private static readonly DEAL_IMAGES_PATH = 'deals';
  private static readonly MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  private static readonly ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

  static async uploadDealImage(
    file: File, 
    dealId: string,
    onProgress?: (progress: UploadProgress) => void
  ): Promise<string> {
    try {
      // Validate file
      this.validateFile(file);

      // Notify compression start
      onProgress?.({ progress: 0, state: 'compressing' });

      // Compress image
      const compressedFile = await compressImage(file, {
        maxWidth: 1200,
        maxHeight: 1200,
        quality: 0.8
      });

      // Generate unique filename
      const filename = `${Date.now()}-${file.name}`;
      const imagePath = `${this.DEAL_IMAGES_PATH}/${dealId}/${filename}`;
      const storageRef = ref(storage, imagePath);

      // Upload with progress tracking
      onProgress?.({ progress: 0, state: 'uploading' });
      
      const snapshot = await uploadBytes(storageRef, compressedFile);
      onProgress?.({ progress: 100, state: 'complete' });

      // Get download URL
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      onProgress?.({ progress: 0, state: 'error' });
      console.error('Error uploading image:', error);
      throw this.handleError(error);
    }
  }

  static async deleteImage(url: string): Promise<void> {
    try {
      const imageRef = ref(storage, url);
      await deleteObject(imageRef);
    } catch (error) {
      console.error('Error deleting image:', error);
      throw new Error('Erreur lors de la suppression de l\'image');
    }
  }

  private static validateFile(file: File): void {
    if (!this.ALLOWED_TYPES.includes(file.type)) {
      throw new Error('Type de fichier non supporté. Utilisez JPG, PNG ou WebP.');
    }

    if (file.size > this.MAX_FILE_SIZE) {
      throw new Error('L\'image ne doit pas dépasser 5 Mo');
    }
  }

  private static handleError(error: unknown): Error {
    if (error instanceof Error) {
      // Handle specific Firebase Storage errors
      if ('code' in error) {
        switch (error.code) {
          case 'storage/unauthorized':
            return new Error('Vous n\'êtes pas autorisé à télécharger des images');
          case 'storage/canceled':
            return new Error('Téléchargement annulé');
          case 'storage/quota-exceeded':
            return new Error('Quota de stockage dépassé');
          default:
            return new Error('Erreur lors du téléchargement de l\'image');
        }
      }
      return error;
    }
    return new Error('Une erreur inattendue est survenue');
  }
}