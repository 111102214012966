import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PreferencesState, UserPreferences } from './types';

const DEFAULT_PREFERENCES: UserPreferences = {
  theme: 'system',
  language: 'fr',
  notifications: {
    email: true,
    push: true,
    deals: true,
    comments: true,
    updates: true,
  },
  privacy: {
    showProfile: true,
    showActivity: true,
    allowGeolocation: false,
  },
  display: {
    compactMode: false,
    showImages: true,
    defaultView: 'grid',
  },
};

export const usePreferences = create<PreferencesState>()(
  persist(
    (set) => ({
      preferences: DEFAULT_PREFERENCES,
      loading: false,
      error: null,

      setPreference: (key, value) => {
        set((state) => ({
          preferences: {
            ...state.preferences,
            [key]: value,
          },
        }));
      },

      resetPreferences: () => {
        set({ preferences: DEFAULT_PREFERENCES });
      },
    }),
    {
      name: 'user-preferences',
    }
  )
);