import { SkeletonLoader } from '../ui/SkeletonLoader';

export function DealCardSkeleton() {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
      <SkeletonLoader className="w-full h-48" />
      <div className="p-4 space-y-4">
        <SkeletonLoader className="h-6 w-3/4" />
        <SkeletonLoader className="h-4 w-full" />
        <div className="flex justify-between items-center">
          <SkeletonLoader className="h-4 w-24" />
          <SkeletonLoader className="h-4 w-20" />
        </div>
        <div className="flex space-x-4">
          <SkeletonLoader className="h-8 w-16" />
          <SkeletonLoader className="h-8 w-16" />
          <SkeletonLoader className="h-8 w-24" />
        </div>
      </div>
    </div>
  );
}