import { Download } from 'lucide-react';
import { Button } from '../ui/Button';
import { useInstallPrompt } from '../../lib/pwa';

export function InstallButton() {
  const { promptInstall, canInstall } = useInstallPrompt();

  if (!canInstall) return null;

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={promptInstall}
      className="flex items-center space-x-2"
    >
      <Download className="w-4 h-4" />
      <span>Installer l'app</span>
    </Button>
  );
}