import { collection, query, where, orderBy, addDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Comment } from '../../types';

export const commentsApi = {
  async addComment(dealId: string, userId: string, text: string): Promise<void> {
    try {
      await addDoc(collection(db, 'comments'), {
        dealId,
        authorId: userId,
        text: text.trim(),
        createdAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error adding comment:', error);
      throw new Error('Erreur lors de l\'ajout du commentaire');
    }
  },

  async deleteComment(commentId: string): Promise<void> {
    try {
      await deleteDoc(doc(db, 'comments', commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
      throw new Error('Erreur lors de la suppression du commentaire');
    }
  },

  getCommentsQuery(dealId: string) {
    return query(
      collection(db, 'comments'),
      where('dealId', '==', dealId),
      orderBy('createdAt', 'asc')
    );
  }
};