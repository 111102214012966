import { useState } from 'react';
import { useAuth } from '../../lib/auth';
import { useNotify } from '../../hooks/useNotify';
import { usePosts } from '../../lib/stores/posts';
import { Button } from '../ui/Button';
import { User, Trash2 } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useUserProfile } from '../../lib/firebase/hooks/useUserProfile';
import { PostComment } from '../../types';
import { toDate } from '../../lib/utils/date';

interface PostCommentsProps {
  postId: string;
  comments: PostComment[];
  onAddComment: (content: string) => void;
}

export function PostComments({ postId, comments, onAddComment }: PostCommentsProps) {
  const [content, setContent] = useState('');
  const { user } = useAuth();
  const { deleteComment } = usePosts();
  const notify = useNotify();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      notify.warning('Connectez-vous pour commenter');
      return;
    }

    if (content.trim()) {
      try {
        await onAddComment(content.trim());
        setContent('');
      } catch (error) {
        notify.error('Erreur lors de l\'ajout du commentaire');
      }
    }
  };

  return (
    <div className="space-y-4">
      {comments.length > 0 && (
        <div className="space-y-4">
          {comments.map((comment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              postId={postId}
              onDelete={deleteComment}
            />
          ))}
        </div>
      )}

      {user && (
        <form onSubmit={handleSubmit} className="space-y-2">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Ajouter un commentaire..."
            className="w-full p-2 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500 focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            rows={2}
          />
          <Button
            type="submit"
            disabled={!content.trim()}
            size="sm"
          >
            Commenter
          </Button>
        </form>
      )}
    </div>
  );
}

interface CommentItemProps {
  comment: PostComment;
  postId: string;
  onDelete: (postId: string, commentId: string) => Promise<void>;
}

function CommentItem({ comment, postId, onDelete }: CommentItemProps) {
  const { user } = useAuth();
  const { profile } = useUserProfile(comment.authorId);
  const notify = useNotify();

  const handleDelete = async () => {
    try {
      await onDelete(postId, comment.id);
      notify.success('Commentaire supprimé');
    } catch (error) {
      notify.error('Erreur lors de la suppression');
    }
  };

  const commentDate = toDate(comment.createdAt);

  return (
    <div className="flex items-start space-x-3 p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
      {profile?.avatarUrl ? (
        <img
          src={profile.avatarUrl}
          alt={profile.username}
          className="w-8 h-8 rounded-full"
        />
      ) : (
        <div className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-600 flex items-center justify-center">
          <User className="w-4 h-4 text-gray-500 dark:text-gray-400" />
        </div>
      )}
      
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="font-medium">{profile?.username || 'Utilisateur'}</span>
            <span className="text-sm text-gray-500">
              {formatDistanceToNow(commentDate, { locale: fr, addSuffix: true })}
            </span>
          </div>
          
          {(user?.id === comment.authorId || user?.role === 'admin') && (
            <Button
              variant="ghost"
              size="sm"
              onClick={handleDelete}
              className="text-red-600 hover:text-red-700"
            >
              <Trash2 className="w-4 h-4" />
            </Button>
          )}
        </div>
        
        <p className="mt-1 text-gray-700 dark:text-gray-300">
          {comment.content}
        </p>
      </div>
    </div>
  );
}