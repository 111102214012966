import { useState } from 'react';
import { Copy, Check, Share2 } from 'lucide-react';
import { Button } from '../ui/Button';
import { useNotify } from '../../hooks/useNotify';
import { ReferralService } from '../../lib/rewards/referral';

interface ReferralCodeProps {
  code: string;
}

export function ReferralCode({ code }: ReferralCodeProps) {
  const [copied, setCopied] = useState(false);
  const notify = useNotify();

  const referralLink = ReferralService.generateReferralLink(code);

  const handleCopy = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      notify.success('Copié avec succès !');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      notify.error('Erreur lors de la copie');
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'SUNDEALS - Code de parrainage',
          text: `Inscrivez-vous sur SUNDEALS avec mon code de parrainage et recevez 50 SUNCOINS !`,
          url: referralLink
        });
      } else {
        await handleCopy(referralLink);
      }
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        notify.error('Erreur lors du partage');
      }
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold mb-4">Code de parrainage</h3>
      
      <p className="text-gray-600 dark:text-gray-300 mb-4">
        Partagez votre code de parrainage et gagnez 50 SUNCOINS pour chaque nouvel utilisateur !
        Votre filleul recevra également 50 SUNCOINS de bienvenue.
      </p>

      <div className="space-y-4">
        {/* Code de parrainage */}
        <div className="flex items-center space-x-2">
          <div className="flex-1 p-3 bg-gray-100 dark:bg-gray-700 rounded-lg font-mono">
            {code}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => handleCopy(code)}
            className="flex items-center space-x-2"
          >
            {copied ? (
              <Check className="w-4 h-4 text-green-500" />
            ) : (
              <Copy className="w-4 h-4" />
            )}
            <span>{copied ? 'Copié !' : 'Copier'}</span>
          </Button>
        </div>

        {/* Lien de parrainage */}
        <div>
          <label className="block text-sm font-medium mb-2">Lien de parrainage</label>
          <div className="flex items-center space-x-2">
            <div className="flex-1 p-3 bg-gray-100 dark:bg-gray-700 rounded-lg text-sm truncate">
              {referralLink}
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={handleShare}
              className="flex items-center space-x-2"
            >
              <Share2 className="w-4 h-4" />
              <span>Partager</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}