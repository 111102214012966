import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  onSnapshot, 
  serverTimestamp,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { Deal } from '../../types';

export const dealsService = {
  subscribeToDeals(onUpdate: (deals: Deal[]) => void, onError: (error: Error) => void) {
    const q = query(collection(db, 'deals'), orderBy('createdAt', 'desc'));
    
    return onSnapshot(q, 
      (snapshot) => {
        const deals = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate() || new Date(),
            updatedAt: data.updatedAt?.toDate() || new Date(),
            expirationDate: data.expirationDate?.toDate() || null,
            likes: data.likes || 0,
            dislikes: data.dislikes || 0,
            likedBy: data.likedBy || [],
            dislikedBy: data.dislikedBy || []
          } as Deal;
        });
        onUpdate(deals);
      },
      (error) => {
        console.error('Deals subscription error:', error);
        onError(error as Error);
      }
    );
  },

  async createDeal(dealData: Omit<Deal, 'id' | 'createdAt' | 'updatedAt'>) {
    try {
      // Clean and prepare the data for Firestore
      const cleanData = {
        title: dealData.title,
        description: dealData.description,
        category: dealData.category,
        price: dealData.price || null,
        normalPrice: dealData.normalPrice || null,
        promoCode: dealData.promoCode || null,
        expirationDate: dealData.expirationDate ? Timestamp.fromDate(dealData.expirationDate) : null,
        imageUrl: dealData.imageUrl || null,
        externalLink: dealData.externalLink || null,
        merchant: dealData.merchant || null,
        authorId: dealData.authorId,
        location: dealData.location ? {
          latitude: Number(dealData.location.latitude),
          longitude: Number(dealData.location.longitude),
          address: dealData.location.address
        } : null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        likes: 0,
        dislikes: 0,
        likedBy: [],
        dislikedBy: [],
        status: 'active'
      };

      // Remove any undefined or invalid values
      Object.keys(cleanData).forEach(key => {
        if (cleanData[key] === undefined) {
          delete cleanData[key];
        }
      });

      const docRef = await addDoc(collection(db, 'deals'), cleanData);
      return docRef.id;
    } catch (error) {
      console.error('Error creating deal:', error);
      throw error;
    }
  }
};