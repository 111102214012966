import { useEffect } from 'react';
import { Coins } from 'lucide-react';
import { useRewards } from '../../lib/rewards/store';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface SuncoinsDisplayProps {
  userId: string;
  variant?: 'compact' | 'full';
}

export function SuncoinsDisplay({ userId, variant = 'full' }: SuncoinsDisplayProps) {
  const { rewards, loading, error, fetchRewards } = useRewards();

  useEffect(() => {
    fetchRewards(userId);
  }, [userId, fetchRewards]);

  if (loading) return <LoadingSpinner />;
  if (error) return null;

  if (variant === 'compact') {
    return (
      <div className="flex items-center space-x-1 text-yellow-500">
        <Coins className="w-4 h-4" />
        <span className="font-medium">{rewards?.suncoins || 0}</span>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white rounded-lg p-4 shadow-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Coins className="w-6 h-6" />
          <h3 className="text-lg font-semibold">SUNCOINS</h3>
        </div>
        <span className="text-2xl font-bold">{rewards?.suncoins || 0}</span>
      </div>
    </div>
  );
}