import { initializeApp } from 'firebase/app';
import { getFirestore, doc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

let messagingInstance: any = null;

export const initializeMessaging = async () => {
  try {
    if (!('Notification' in window)) {
      console.warn('Les notifications ne sont pas supportées');
      return null;
    }

    // Vérifier l'enregistrement du Service Worker
    const swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    console.log('Service Worker enregistré:', swRegistration);

    if (!messagingInstance) {
      messagingInstance = getMessaging(app);
      
      // Configurer la gestion des messages au premier plan
      onMessage(messagingInstance, (payload) => {
        console.log('Message reçu au premier plan:', payload);
        
        // Afficher une notification même en premier plan
        if (Notification.permission === 'granted') {
          new Notification(payload.notification?.title || 'SUNDEALS', {
            body: payload.notification?.body,
            icon: '/icons/icon-192.png',
            badge: '/icons/icon-192.png',
            data: payload.data,
            tag: payload.data?.id || 'default'
          });
        }
      });

      // Obtenir et sauvegarder le token FCM
      try {
        const currentToken = await getToken(messagingInstance, {
          vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: swRegistration
        });
        
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          // Sauvegarder le token dans Firestore pour l'utilisateur
          const user = auth.currentUser;
          if (user) {
            await saveUserToken(user.uid, currentToken);
          }
        } else {
          console.warn('Impossible d\'obtenir le token FCM');
        }
      } catch (err) {
        console.error('Erreur lors de la récupération du token:', err);
      }
    }

    return messagingInstance;
  } catch (error) {
    console.error('Erreur d\'initialisation de Firebase Messaging:', error);
    return null;
  }
};

async function saveUserToken(userId: string, token: string) {
  try {
    const tokenRef = doc(db, 'users', userId);
    await updateDoc(tokenRef, {
      fcmTokens: arrayUnion(token),
      updatedAt: serverTimestamp()
    });
    console.log('Token FCM sauvegardé pour l\'utilisateur:', userId);
  } catch (error) {
    console.error('Erreur lors de la sauvegarde du token:', error);
  }
}