import { create } from 'zustand';
import { collection, query, where, getDocs, onSnapshot, addDoc, serverTimestamp, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebase/config';
import { startOfToday } from 'date-fns';

interface AdminStats {
  totalDeals: number;
  totalUsers: number;
  activeDeals: number;
  moderatedDeals: number;
  newUsersToday: number;
}

interface AdminState {
  stats: AdminStats;
  loading: boolean;
  error: string | null;
  setStats: (stats: AdminStats) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  fetchStats: () => Promise<void>;
  moderateDeal: (dealId: string, action: 'approve' | 'reject') => Promise<void>;
  deleteDeal: (dealId: string) => Promise<void>;
  banUser: (userId: string) => Promise<void>;
  unbanUser: (userId: string) => Promise<void>;
  subscribeToStats: () => () => void;
  sendNotification: (title: string, description: string, url?: string) => Promise<void>;
}

export const useAdmin = create<AdminState>((set) => ({
  stats: {
    totalDeals: 0,
    totalUsers: 0,
    activeDeals: 0,
    moderatedDeals: 0,
    newUsersToday: 0
  },
  loading: false,
  error: null,

  fetchStats: async () => {
    try {
      set({ loading: true, error: null });
      
      // Récupérer le nombre total de deals
      const dealsSnapshot = await getDocs(collection(db, 'deals'));
      const totalDeals = dealsSnapshot.size;

      // Récupérer les deals actifs
      const activeDealsQuery = query(collection(db, 'deals'), where('status', '==', 'active'));
      const activeDealsSnapshot = await getDocs(activeDealsQuery);
      const activeDeals = activeDealsSnapshot.size;

      // Récupérer les deals modérés
      const moderatedDealsQuery = query(collection(db, 'deals'), where('status', '==', 'moderated'));
      const moderatedDealsSnapshot = await getDocs(moderatedDealsQuery);
      const moderatedDeals = moderatedDealsSnapshot.size;

      // Récupérer le nombre total d'utilisateurs
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const totalUsers = usersSnapshot.size;

      // Récupérer les nouveaux utilisateurs d'aujourd'hui
      const today = startOfToday();
      const newUsersQuery = query(
        collection(db, 'users'),
        where('createdAt', '>=', today)
      );
      const newUsersSnapshot = await getDocs(newUsersQuery);
      const newUsersToday = newUsersSnapshot.size;

      set({
        stats: {
          totalDeals,
          totalUsers,
          activeDeals,
          moderatedDeals,
          newUsersToday
        },
        loading: false
      });
    } catch (error) {
      console.error('Error fetching admin stats:', error);
      set({ error: 'Erreur lors de la récupération des statistiques', loading: false });
    }
  },

  moderateDeal: async (dealId: string, action: 'approve' | 'reject') => {
    try {
      set({ loading: true, error: null });
      const dealRef = doc(db, 'deals', dealId);
      await updateDoc(dealRef, {
        status: action === 'approve' ? 'active' : 'moderated',
        updatedAt: serverTimestamp()
      });
      set({ loading: false });
    } catch (error) {
      console.error('Error moderating deal:', error);
      set({ error: 'Erreur lors de la modération du bon plan', loading: false });
      throw error;
    }
  },

  deleteDeal: async (dealId: string) => {
    try {
      set({ loading: true, error: null });
      const dealRef = doc(db, 'deals', dealId);
      await deleteDoc(dealRef);
      set({ loading: false });
    } catch (error) {
      console.error('Error deleting deal:', error);
      set({ error: 'Erreur lors de la suppression du bon plan', loading: false });
      throw error;
    }
  },

  banUser: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'banned',
        updatedAt: serverTimestamp()
      });
      set({ loading: false });
    } catch (error) {
      console.error('Error banning user:', error);
      set({ error: 'Erreur lors du bannissement de l\'utilisateur', loading: false });
      throw error;
    }
  },

  unbanUser: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        status: 'active',
        updatedAt: serverTimestamp()
      });
      set({ loading: false });
    } catch (error) {
      console.error('Error unbanning user:', error);
      set({ error: 'Erreur lors de la réactivation de l\'utilisateur', loading: false });
      throw error;
    }
  },

  subscribeToStats: () => {
    const unsubscribers: (() => void)[] = [];

    // Écouter les changements dans la collection deals
    const dealsUnsubscribe = onSnapshot(
      collection(db, 'deals'),
      async (snapshot) => {
        try {
          const totalDeals = snapshot.size;
          const activeDeals = snapshot.docs.filter(doc => doc.data().status === 'active').length;
          const moderatedDeals = snapshot.docs.filter(doc => doc.data().status === 'moderated').length;

          set(state => ({
            stats: {
              ...state.stats,
              totalDeals,
              activeDeals,
              moderatedDeals
            }
          }));
        } catch (error) {
          console.error('Error in deals subscription:', error);
        }
      }
    );
    unsubscribers.push(dealsUnsubscribe);

    // Écouter les changements dans la collection users
    const usersUnsubscribe = onSnapshot(
      collection(db, 'users'),
      async (snapshot) => {
        try {
          const totalUsers = snapshot.size;
          const today = startOfToday();
          const newUsersToday = snapshot.docs.filter(
            doc => doc.data().createdAt?.toDate() >= today
          ).length;

          set(state => ({
            stats: {
              ...state.stats,
              totalUsers,
              newUsersToday
            }
          }));
        } catch (error) {
          console.error('Error in users subscription:', error);
        }
      }
    );
    unsubscribers.push(usersUnsubscribe);

    // Retourner une fonction pour désabonner toutes les souscriptions
    return () => unsubscribers.forEach(unsubscribe => unsubscribe());
  },

  setStats: (stats) => set({ stats }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  sendNotification: async (title: string, description: string, url?: string) => {
    try {
      set({ loading: true, error: null });
      await addDoc(collection(db, 'notifications'), {
        title,
        description,
        url,
        createdAt: serverTimestamp(),
        status: 'pending'
      });
      set({ loading: false });
    } catch (error) {
      console.error('Error sending notification:', error);
      set({ error: 'Erreur lors de l\'envoi de la notification', loading: false });
      throw error;
    }
  }
}));