import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useNotificationStore } from '../../lib/notifications/store';
import { Notification } from '../../lib/notifications/types';

interface NotificationItemProps {
  notification: Notification;
}

export function NotificationItem({ notification }: NotificationItemProps) {
  const navigate = useNavigate();
  const { markAsRead } = useNotificationStore();

  const handleClick = useCallback(() => {
    if (!notification.read) {
      markAsRead(notification.id);
    }
    if (notification.link) {
      navigate(notification.link);
    }
  }, [notification, markAsRead, navigate]);

  return (
    <div
      onClick={handleClick}
      className={`p-4 border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors ${
        !notification.read ? 'bg-blue-50 dark:bg-blue-900/20' : ''
      }`}
    >
      <div className="flex justify-between items-start mb-1">
        <h4 className="font-medium">{notification.title}</h4>
        <span className="text-xs text-gray-500">
          {formatDistanceToNow(notification.createdAt, {
            addSuffix: true,
            locale: fr,
          })}
        </span>
      </div>
      <p className="text-sm text-gray-600 dark:text-gray-300">
        {notification.message}
      </p>
    </div>
  );
}