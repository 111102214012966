import React from 'react';

function CGU() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Conditions Générales d'Utilisation</h1>

      <div className="prose dark:prose-invert">
        <p className="text-sm text-gray-500 mb-8">Dernière mise à jour : {new Date().toLocaleDateString()}</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Présentation</h2>
          <p>
            Les présentes conditions générales d'utilisation (CGU) régissent l'utilisation de la plateforme SUNDEALS, 
            accessible via le site web sundeals.io. En utilisant SUNDEALS, vous acceptez d'être lié par ces conditions.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Services proposés</h2>
          <p>
            SUNDEALS est une plateforme communautaire permettant aux utilisateurs de partager et découvrir des bons plans.
            Les services incluent notamment :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Le partage de bons plans et codes promos</li>
            <li>L'interaction avec la communauté via des commentaires</li>
            <li>La géolocalisation des offres</li>
            <li>Les notifications personnalisées</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Inscription et compte utilisateur</h2>
          <p>
            L'inscription est gratuite mais nécessaire pour publier du contenu. Vous vous engagez à :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Fournir des informations exactes et à jour</li>
            <li>Maintenir la confidentialité de vos identifiants</li>
            <li>Ne pas créer plusieurs comptes ou un compte au nom d'un tiers</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Règles de publication</h2>
          <p>
            Les utilisateurs s'engagent à publier des contenus :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Véridiques et vérifiables</li>
            <li>Respectueux de la législation en vigueur</li>
            <li>Non promotionnels ou publicitaires sans autorisation</li>
            <li>Respectueux des droits des tiers</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Modération</h2>
          <p>
            SUNDEALS se réserve le droit de :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>Modérer tout contenu avant ou après publication</li>
            <li>Supprimer tout contenu inapproprié</li>
            <li>Suspendre ou supprimer un compte utilisateur</li>
            <li>Modifier ces CGU à tout moment</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Propriété intellectuelle</h2>
          <p>
            Tous les contenus originaux de SUNDEALS sont protégés par le droit d'auteur. Les utilisateurs conservent 
            leurs droits sur les contenus qu'ils publient mais accordent à SUNDEALS une licence non-exclusive 
            d'utilisation.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Responsabilité</h2>
          <p>
            SUNDEALS ne peut être tenu responsable :
          </p>
          <ul className="list-disc pl-6 mt-2">
            <li>De l'exactitude des bons plans partagés par les utilisateurs</li>
            <li>Des problèmes techniques ou interruptions de service</li>
            <li>Des dommages directs ou indirects liés à l'utilisation du service</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Protection des données</h2>
          <p>
            Le traitement des données personnelles est détaillé dans notre Politique de Confidentialité. 
            En utilisant SUNDEALS, vous acceptez notre politique de protection des données.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. Contact</h2>
          <p>
            Pour toute question concernant ces CGU, vous pouvez nous contacter à l'adresse suivante :<br />
            Email : contact@sundeals.io
          </p>
        </section>
      </div>
    </div>
  );
}

export default CGU;