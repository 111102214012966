import { doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../config';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { User } from '../../../types';

export async function createUserProfile(user: User) {
  try {
    const userRef = doc(db, 'users', user.id);
    await setDoc(userRef, {
      ...user,
      createdAt: new Date(),
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error creating user profile:', error);
    throw new Error('Erreur lors de la création du profil utilisateur');
  }
}

export async function updateUserProfile(userId: string, data: Partial<User>) {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      ...data,
      updatedAt: new Date()
    });
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw new Error('Erreur lors de la mise à jour du profil');
  }
}

export async function uploadUserAvatar(userId: string, file: File): Promise<string> {
  try {
    const storageRef = ref(storage, `users/${userId}/avatar/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    
    // Mettre à jour l'URL de l'avatar dans le profil utilisateur
    await updateUserProfile(userId, { avatarUrl: downloadURL });
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading avatar:', error);
    throw new Error('Erreur lors du téléchargement de l\'avatar');
  }
}

export async function deleteUserAvatar(userId: string) {
  try {
    const user = await getUserProfile(userId);
    if (user?.avatarUrl) {
      const storageRef = ref(storage, user.avatarUrl);
      await deleteObject(storageRef);
      await updateUserProfile(userId, { avatarUrl: null });
    }
  } catch (error) {
    console.error('Error deleting avatar:', error);
    throw new Error('Erreur lors de la suppression de l\'avatar');
  }
}

export async function getUserProfile(userId: string): Promise<User | null> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) return null;
    
    return {
      id: userDoc.id,
      ...userDoc.data()
    } as User;
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw new Error('Erreur lors de la récupération du profil');
  }
}