import { Command } from 'lucide-react';
import { Button } from './Button';
import { useState } from 'react';

export function KeyboardShortcuts() {
  const [isOpen, setIsOpen] = useState(false);

  const shortcuts = [
    { key: 'Ctrl + K', description: 'Rechercher' },
    { key: 'H', description: 'Accueil' },
    { key: 'N', description: 'Nouveau bon plan' },
    { key: 'C', description: 'Communauté' },
    { key: 'Esc', description: 'Fermer / Désélectionner' },
  ];

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setIsOpen(true)}
        className="hidden md:flex items-center space-x-2"
        aria-label="Afficher les raccourcis clavier"
      >
        <Command className="h-4 w-4" />
        <span>Raccourcis</span>
      </Button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
            <h2 className="text-lg font-semibold mb-4">Raccourcis clavier</h2>
            <div className="space-y-2">
              {shortcuts.map(({ key, description }) => (
                <div key={key} className="flex justify-between items-center">
                  <kbd className="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-sm">
                    {key}
                  </kbd>
                  <span className="text-gray-600 dark:text-gray-300">{description}</span>
                </div>
              ))}
            </div>
            <Button
              className="w-full mt-6"
              onClick={() => setIsOpen(false)}
            >
              Fermer
            </Button>
          </div>
        </div>
      )}
    </>
  );
}