import { useEffect } from 'react';
import { useNotifications } from '../../lib/notifications';
import { Toast } from './Toast';

export function ToastContainer() {
  const { notifications, removeNotification, clearNotifications } = useNotifications();

  useEffect(() => {
    return () => {
      clearNotifications();
    };
  }, [clearNotifications]);

  return (
    <div className="fixed bottom-0 right-0 p-4 z-50 max-w-md w-full">
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          type={notification.type}
          message={notification.message}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </div>
  );
}