import { create } from 'zustand';
import { Post, PostComment } from '../../types';
import { 
  collection, 
  addDoc, 
  query, 
  orderBy, 
  onSnapshot, 
  serverTimestamp, 
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  increment,
  getDoc
} from 'firebase/firestore';
import { db } from '../firebase/config';

interface PostsState {
  posts: Post[];
  loading: boolean;
  error: string | null;
  addPost: (post: Omit<Post, 'id' | 'createdAt'>) => Promise<string>;
  addComment: (postId: string, userId: string, content: string) => Promise<void>;
  deleteComment: (postId: string, commentId: string) => Promise<void>;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  initializeRealtimePosts: () => () => void;
}

export const usePosts = create<PostsState>((set, get) => ({
  posts: [],
  loading: false,
  error: null,

  addPost: async (post) => {
    try {
      set({ loading: true, error: null });
      const docRef = await addDoc(collection(db, 'posts'), {
        ...post,
        createdAt: serverTimestamp(),
        likes: 0,
        comments: 0,
        postComments: []
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding post:', error);
      set({ error: 'Erreur lors de la publication' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  addComment: async (postId: string, userId: string, content: string) => {
    try {
      set({ loading: true, error: null });
      
      // Get post reference
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);
      
      if (!postDoc.exists()) {
        throw new Error('Post not found');
      }

      // Create new comment
      const newComment: Omit<PostComment, 'id'> = {
        postId,
        authorId: userId,
        content: content.trim(),
        createdAt: new Date()
      };

      // Add comment to post's comments subcollection
      const commentRef = await addDoc(collection(db, 'posts', postId, 'comments'), newComment);

      // Update post's comment count
      await updateDoc(postRef, {
        comments: increment(1),
        postComments: [...(postDoc.data().postComments || []), { id: commentRef.id, ...newComment }]
      });

    } catch (error) {
      console.error('Error adding comment:', error);
      set({ error: 'Erreur lors de l\'ajout du commentaire' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  deleteComment: async (postId: string, commentId: string) => {
    try {
      set({ loading: true, error: null });
      
      // Delete comment from subcollection
      await deleteDoc(doc(db, 'posts', postId, 'comments', commentId));
      
      // Update post document
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);
      
      if (postDoc.exists()) {
        const currentComments = postDoc.data().postComments || [];
        await updateDoc(postRef, {
          comments: increment(-1),
          postComments: currentComments.filter((c: PostComment) => c.id !== commentId)
        });
      }

    } catch (error) {
      console.error('Error deleting comment:', error);
      set({ error: 'Erreur lors de la suppression du commentaire' });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),

  initializeRealtimePosts: () => {
    const unsubscribers: (() => void)[] = [];

    // Subscribe to posts
    const postsQuery = query(collection(db, 'posts'), orderBy('createdAt', 'desc'));
    const unsubPosts = onSnapshot(postsQuery, async (snapshot) => {
      try {
        const posts = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const postData = doc.data();
            
            // Get comments for this post
            const commentsQuery = query(
              collection(db, 'posts', doc.id, 'comments'),
              orderBy('createdAt', 'asc')
            );
            
            const commentsSnapshot = await getDocs(commentsQuery);
            const comments = commentsSnapshot.docs.map(commentDoc => ({
              id: commentDoc.id,
              ...commentDoc.data(),
              createdAt: commentDoc.data().createdAt?.toDate() || new Date()
            })) as PostComment[];

            return {
              id: doc.id,
              ...postData,
              createdAt: postData.createdAt?.toDate() || new Date(),
              postComments: comments
            } as Post;
          })
        );

        set({ posts, loading: false });
      } catch (error) {
        console.error('Error in posts subscription:', error);
        set({ error: 'Erreur lors du chargement des publications', loading: false });
      }
    });

    unsubscribers.push(unsubPosts);

    return () => {
      unsubscribers.forEach(unsub => unsub());
    };
  }
}));