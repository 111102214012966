import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config';
import { User } from '../../../types';
import { useNotify } from '../../../hooks/useNotify';

export function useUserProfile(userId: string) {
  const [profile, setProfile] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const notify = useNotify();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, 'users', userId),
      (doc) => {
        if (doc.exists()) {
          setProfile({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate() || new Date()
          } as User);
        } else {
          setProfile(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching user profile:', error);
        notify.error('Erreur lors du chargement du profil');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId, notify]);

  return { profile, loading };
}