import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { User } from 'lucide-react';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { useCommunity } from '../../lib/stores/community';
import { LoadingSpinner } from '../ui/LoadingSpinner';

interface PopularUsersProps {
  compact?: boolean;
}

export function PopularUsers({ compact = false }: PopularUsersProps) {
  const { user } = useAuth();
  const { activeUsers, loading, error, fetchActiveUsers } = useCommunity();

  useEffect(() => {
    fetchActiveUsers();
  }, [fetchActiveUsers]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 dark:text-red-400">
        {error}
      </div>
    );
  }

  if (activeUsers.length === 0) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400 p-4">
        Aucun membre actif pour le moment
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-lg font-semibold mb-4">
        {compact ? 'Top Contributeurs' : 'Membres les plus actifs'}
      </h2>

      <div className="space-y-4">
        {activeUsers.slice(0, compact ? 3 : 5).map((member) => (
          <div
            key={member.user.id}
            className="flex items-center justify-between"
          >
            <Link
              to={`/users/${member.user.id}`}
              className="flex items-center space-x-3 flex-1 hover:bg-gray-50 dark:hover:bg-gray-700 p-2 rounded-lg transition-colors"
            >
              {member.user.avatarUrl ? (
                <img
                  src={member.user.avatarUrl}
                  alt={member.user.username}
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
                  <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                </div>
              )}
              <div>
                <div className="font-medium text-gray-900 dark:text-white">
                  {member.user.username}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {member.dealsCount} bons plans • {member.reputation} points
                </div>
              </div>
            </Link>

            {!compact && user && user.id !== member.user.id && (
              <Button variant="outline" size="sm">
                Suivre
              </Button>
            )}
          </div>
        ))}
      </div>

      {compact && activeUsers.length > 3 && (
        <Link
          to="/commu?tab=users"
          className="block text-center text-sm text-blue-600 dark:text-blue-400 hover:underline mt-4"
        >
          Voir tous les membres
        </Link>
      )}
    </div>
  );
}