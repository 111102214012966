import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Trash2, User } from 'lucide-react';
import { Comment } from '../../types';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { useUserProfile } from '../../lib/firebase/hooks/useUserProfile';
import { SkeletonLoader } from '../ui/SkeletonLoader';

interface CommentListProps {
  comments: Comment[];
  onDelete?: (commentId: string) => void;
}

export function CommentList({ comments, onDelete }: CommentListProps) {
  if (comments.length === 0) {
    return (
      <div className="text-center py-4">
        <p className="text-gray-500 dark:text-gray-400">Aucun commentaire pour le moment</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {comments.map((comment) => (
        <CommentItem 
          key={comment.id} 
          comment={comment}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
}

interface CommentItemProps {
  comment: Comment;
  onDelete?: (commentId: string) => void;
}

function CommentItem({ comment, onDelete }: CommentItemProps) {
  const { user } = useAuth();
  const { profile, loading } = useUserProfile(comment.authorId);

  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg p-4">
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">
          {profile?.avatarUrl ? (
            <img
              src={profile.avatarUrl}
              alt={profile?.username || 'Avatar'}
              className="w-10 h-10 rounded-full"
            />
          ) : (
            <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
              <User className="w-5 h-5 text-blue-600 dark:text-blue-400" />
            </div>
          )}
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {loading ? (
                <SkeletonLoader className="h-5 w-32" />
              ) : (
                <span className="font-medium text-gray-900 dark:text-gray-100">
                  {profile?.username || 'Utilisateur inconnu'}
                </span>
              )}
              <span className="text-gray-500 dark:text-gray-400 text-sm">
                {formatDistanceToNow(comment.createdAt, { locale: fr, addSuffix: true })}
              </span>
            </div>
            
            {(user?.id === comment.authorId || user?.role === 'admin') && onDelete && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => onDelete(comment.id)}
                className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
          </div>
          
          <p className="mt-1 text-gray-700 dark:text-gray-300 break-words">
            {comment.text}
          </p>
        </div>
      </div>
    </div>
  );
}