import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/auth';
import { useNotify } from '../../hooks/useNotify';
import { Post } from '../../types';
import { PostCard } from './PostCard';
import { PostForm } from './PostForm';
import { Button } from '../ui/Button';
import { usePosts } from '../../lib/posts';
import { useDeals } from '../../lib/deals';

export function CommunityFeed() {
  const { isAuthenticated, user } = useAuth();
  const notify = useNotify();
  const { posts, addPost, likePost, deletePost, addComment, initializeRealtimePosts } = usePosts();
  const { deals, initializeRealtimeDeals } = useDeals();
  const [showPostForm, setShowPostForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Initialize realtime subscriptions
  useEffect(() => {
    const unsubPosts = initializeRealtimePosts();
    const unsubDeals = initializeRealtimeDeals();
    return () => {
      unsubPosts();
      unsubDeals();
    };
  }, [initializeRealtimePosts, initializeRealtimeDeals]);

  const handlePost = async (content: string, dealId?: string) => {
    if (!user) {
      notify.warning('Connectez-vous pour publier');
      return;
    }

    try {
      setIsSubmitting(true);
      const newPost = {
        content,
        authorId: user.id,
        dealId,
        likes: 0,
        comments: 0,
        postComments: []
      };
      
      await addPost(newPost);
      setShowPostForm(false);
      notify.success('Votre message a été publié avec succès !');
    } catch (error) {
      console.error('Error publishing post:', error);
      notify.error('Une erreur est survenue lors de la publication');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLike = async (postId: string) => {
    if (!isAuthenticated) {
      notify.warning('Connectez-vous pour interagir avec les publications');
      return;
    }
    likePost(postId);
  };

  const handleComment = async (postId: string, content: string) => {
    if (!isAuthenticated) {
      notify.warning('Connectez-vous pour commenter');
      return;
    }
    addComment(postId);
    notify.success('Commentaire ajouté');
  };

  const handleDelete = async (postId: string) => {
    deletePost(postId);
    notify.success('Publication supprimée');
  };

  return (
    <div className="space-y-6">
      {isAuthenticated && (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 md:p-6">
          {showPostForm ? (
            <PostForm 
              onSubmit={handlePost}
              onCancel={() => setShowPostForm(false)}
              isSubmitting={isSubmitting}
              deals={deals}
            />
          ) : (
            <Button
              variant="outline"
              className="w-full text-left"
              onClick={() => setShowPostForm(true)}
            >
              Partagez quelque chose avec la communauté...
            </Button>
          )}
        </div>
      )}

      <div className="space-y-4">
        {posts.map((post) => (
          <PostCard 
            key={post.id} 
            post={post}
            onLike={() => handleLike(post.id)}
            onComment={(content) => handleComment(post.id, content)}
            onDelete={() => handleDelete(post.id)}
          />
        ))}
      </div>
    </div>
  );
}