import { BarChart3, Users, ShoppingBag, AlertTriangle } from 'lucide-react';

interface StatsCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  description: string;
}

function StatsCard({ title, value, icon, description }: StatsCardProps) {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 sm:p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="text-2xl sm:text-3xl font-bold mt-1">{value}</p>
        </div>
        <div className="p-3 bg-blue-100 dark:bg-blue-900 rounded-full">
          {icon}
        </div>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-400 mt-4">{description}</p>
    </div>
  );
}

interface AdminStatsProps {
  stats: {
    totalDeals: number;
    totalUsers: number;
    activeDeals: number;
    moderatedDeals: number;
    newUsersToday: number;
  };
}

export function AdminStats({ stats }: AdminStatsProps) {
  return (
    <div className="grid gap-4 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
      <StatsCard
        title="Total des bons plans"
        value={stats.totalDeals}
        icon={<ShoppingBag className="h-6 w-6 text-blue-600 dark:text-blue-400" />}
        description="Nombre total de bons plans publiés"
      />
      
      <StatsCard
        title="Utilisateurs inscrits"
        value={stats.totalUsers}
        icon={<Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />}
        description={`+${stats.newUsersToday} aujourd'hui`}
      />
      
      <StatsCard
        title="Bons plans actifs"
        value={stats.activeDeals}
        icon={<BarChart3 className="h-6 w-6 text-blue-600 dark:text-blue-400" />}
        description="Bons plans en ligne"
      />
      
      <StatsCard
        title="Bons plans modérés"
        value={stats.moderatedDeals}
        icon={<AlertTriangle className="h-6 w-6 text-blue-600 dark:text-blue-400" />}
        description="Bons plans nécessitant une action"
      />
    </div>
  );
}