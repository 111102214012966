import { PreferencesPanel } from '../components/settings/PreferencesPanel';

function SettingsPage() {
  return (
    <div className="max-w-4xl mx-auto px-4">
      <h1 className="text-2xl font-bold mb-6">Paramètres</h1>
      <PreferencesPanel />
    </div>
  );
}

export default SettingsPage;