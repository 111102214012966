import { useState, useRef, useEffect } from 'react';
import { MapPin } from 'lucide-react';
import { useAddressAutocomplete } from '../../hooks/useAddressAutocomplete';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

interface AddressAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  onSelect: (address: { display_name: string; lat: string; lon: string }) => void;
  error?: string;
}

export function AddressAutocomplete({
  value,
  onChange,
  onSelect,
  error
}: AddressAutocompleteProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { suggestions, loading, fetchSuggestions } = useAddressAutocomplete();
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => setIsOpen(false));

  useEffect(() => {
    if (value) {
      fetchSuggestions(value);
    }
  }, [value, fetchSuggestions]);

  return (
    <div ref={containerRef} className="relative">
      <div className="relative">
        <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        <input
          type="text"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700 pl-10"
          placeholder="123 rue du Commerce, 75001 Paris"
        />
      </div>

      {error && (
        <p className="mt-1 text-sm text-red-600 dark:text-red-400">
          {error}
        </p>
      )}

      {isOpen && value.length >= 3 && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
          {loading ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              Recherche en cours...
            </div>
          ) : suggestions.length > 0 ? (
            <ul className="max-h-60 overflow-auto">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                  onClick={() => {
                    onSelect(suggestion);
                    onChange(suggestion.display_name);
                    setIsOpen(false);
                  }}
                >
                  <div className="font-medium">{suggestion.display_name}</div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              Aucune adresse trouvée
            </div>
          )}
        </div>
      )}
    </div>
  );
}