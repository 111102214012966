import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { initializeNotifications } from './lib/notifications/initialize';

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found');

// Initialize notifications in production
if (import.meta.env.PROD) {
  initializeNotifications().then(({ success, error }) => {
    if (!success) {
      console.warn('Notifications initialization failed:', error);
    }
  });
}

createRoot(root).render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);