import { Link } from 'react-router-dom';
import { Home, PlusCircle, Users, User, LogOut, MapPin, Shield, Info, Settings } from 'lucide-react';
import { useAuth } from '../../lib/auth';
import { Button } from '../ui/Button';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onAuthClick: () => void;
}

export function MobileMenu({ isOpen, onClose, onAuthClick }: MobileMenuProps) {
  const { user, logout } = useAuth();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 lg:hidden">
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />
      
      <div className="fixed inset-y-0 right-0 w-64 bg-white dark:bg-gray-800 shadow-xl">
        <div className="flex flex-col h-full">
          <div className="p-4 border-b dark:border-gray-700">
            {user ? (
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                  <User className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <div>
                  <div className="font-medium">{user.username}</div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {user.email}
                  </div>
                </div>
              </div>
            ) : (
              <Button
                variant="primary"
                className="w-full"
                onClick={() => {
                  onClose();
                  onAuthClick();
                }}
              >
                Se connecter
              </Button>
            )}
          </div>

          <nav className="flex-1 overflow-y-auto p-4">
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={onClose}
                >
                  <Home className="w-5 h-5" />
                  <span>Accueil</span>
                </Link>
              </li>
              {user && (
                <>
                  <li>
                    <Link
                      to="/nouveau"
                      className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={onClose}
                    >
                      <PlusCircle className="w-5 h-5" />
                      <span>Publier un bon plan</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={onClose}
                    >
                      <User className="w-5 h-5" />
                      <span>Mon Profil</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/parametres"
                      className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={onClose}
                    >
                      <Settings className="w-5 h-5" />
                      <span>Paramètres</span>
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link
                  to="/commu"
                  className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={onClose}
                >
                  <Users className="w-5 h-5" />
                  <span>Communauté</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/autour-de-moi"
                  className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={onClose}
                >
                  <MapPin className="w-5 h-5" />
                  <span>Autour de moi</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/a-propos"
                  className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={onClose}
                >
                  <Info className="w-5 h-5" />
                  <span>À propos</span>
                </Link>
              </li>
              {user?.role === 'admin' && (
                <li>
                  <Link
                    to="/admin"
                    className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={onClose}
                  >
                    <Shield className="w-5 h-5" />
                    <span>Admin</span>
                  </Link>
                </li>
              )}
            </ul>
          </nav>

          {user && (
            <div className="p-4 border-t dark:border-gray-700">
              <button
                onClick={() => {
                  logout();
                  onClose();
                }}
                className="flex items-center space-x-3 w-full p-2 rounded-lg text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20"
              >
                <LogOut className="w-5 h-5" />
                <span>Se déconnecter</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}