import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { ThumbsUp, ThumbsDown, MessageSquare, ExternalLink, MapPin } from 'lucide-react';
import { Deal } from '../../types';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { useInteractions } from '../../hooks/useInteractions';
import { useUserProfile } from '../../lib/firebase/hooks/useUserProfile';
import { toDate } from '../../lib/utils/date';

interface DealCardProps {
  deal: Deal;
  onLike: () => void;
  onDislike: () => void;
}

export function DealCard({ deal, onLike, onDislike }: DealCardProps) {
  const { user } = useAuth();
  const { loading } = useInteractions();
  const { profile } = useUserProfile(deal.authorId);

  const isLiked = user && deal.likedBy?.includes(user.id);
  const isDisliked = user && deal.dislikedBy?.includes(user.id);
  const createdAt = toDate(deal.createdAt);

  return (
    <article className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
      {deal.imageUrl && (
        <Link to={`/deals/${deal.id}`}>
          <img
            src={deal.imageUrl}
            alt={deal.title}
            className="w-full h-48 object-cover transition-transform hover:scale-105"
          />
        </Link>
      )}
      
      <div className="p-4">
        <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400 mb-2">
          <Link to={`/users/${deal.authorId}`} className="hover:underline">
            {profile?.username || 'Utilisateur'}
          </Link>
          <span>•</span>
          <span>
            {formatDistanceToNow(createdAt, { 
              locale: fr, 
              addSuffix: true 
            })}
          </span>
        </div>

        <Link
          to={`/deals/${deal.id}`}
          className="block hover:text-blue-600 dark:hover:text-blue-400"
        >
          <h3 className="text-xl font-semibold mb-2">{deal.title}</h3>
        </Link>
        
        <p className="text-gray-600 dark:text-gray-300 mb-4">{deal.description}</p>

        {deal.location?.address && (
          <div className="flex items-center text-gray-500 dark:text-gray-400 mb-4">
            <MapPin className="h-4 w-4 mr-1" />
            <span className="text-sm">{deal.location.address}</span>
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Button
              variant="ghost"
              size="sm"
              onClick={onLike}
              disabled={loading}
              className={`flex items-center space-x-1 ${isLiked ? 'text-green-600' : ''}`}
            >
              <ThumbsUp className={`h-4 w-4 ${isLiked ? 'fill-current' : ''}`} />
              <span>{deal.likes || 0}</span>
            </Button>
            
            <Button
              variant="ghost"
              size="sm"
              onClick={onDislike}
              disabled={loading}
              className={`flex items-center space-x-1 ${isDisliked ? 'text-red-600' : ''}`}
            >
              <ThumbsDown className={`h-4 w-4 ${isDisliked ? 'fill-current' : ''}`} />
              <span>{deal.dislikes || 0}</span>
            </Button>

            <Link
              to={`/deals/${deal.id}#comments`}
              className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            >
              <MessageSquare className="h-4 w-4" />
              <span>Commenter</span>
            </Link>
          </div>

          {deal.externalLink && (
            <a
              href={deal.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
            >
              <ExternalLink className="h-4 w-4 mr-1" />
              Voir l'offre
            </a>
          )}
        </div>
      </div>
    </article>
  );
}