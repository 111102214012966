import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { Post } from '../../types';
import { PostActions } from './PostActions';
import { PostComments } from './PostComments';
import { useState } from 'react';
import { useAuth } from '../../lib/auth';
import { useUserProfile } from '../../lib/firebase/hooks/useUserProfile';
import { SkeletonLoader } from '../ui/SkeletonLoader';
import { useDeals } from '../../lib/deals';
import { usePosts } from '../../lib/stores/posts';

interface PostCardProps {
  post: Post;
  onLike: () => void;
  onDelete?: () => void;
}

export function PostCard({ post, onLike, onDelete }: PostCardProps) {
  const [showComments, setShowComments] = useState(post.comments > 0);
  const { user } = useAuth();
  const { profile, loading } = useUserProfile(post.authorId);
  const { deals } = useDeals();
  const { addComment } = usePosts();
  
  const linkedDeal = post.dealId ? deals.find(d => d.id === post.dealId) : undefined;

  const handleAddComment = async (content: string) => {
    if (!user) return;
    await addComment(post.id, user.id, content);
  };

  return (
    <article className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <div className="flex items-start justify-between mb-4">
        <Link
          to={`/users/${post.authorId}`}
          className="flex items-center space-x-3"
        >
          <img
            src={profile?.avatarUrl || `https://api.dicebear.com/7.x/avatars/svg?seed=${post.authorId}`}
            alt="Avatar"
            className="w-10 h-10 rounded-full"
          />
          <div>
            {loading ? (
              <SkeletonLoader className="h-5 w-32" />
            ) : (
              <div className="font-medium">{profile?.username || 'Utilisateur inconnu'}</div>
            )}
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {formatDistanceToNow(post.createdAt, { locale: fr, addSuffix: true })}
            </div>
          </div>
        </Link>
      </div>

      <p className="text-gray-800 dark:text-gray-200 mb-4">{post.content}</p>

      {linkedDeal && (
        <Link
          to={`/deals/${linkedDeal.id}`}
          className="block mb-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
        >
          <div className="flex items-start space-x-4">
            {linkedDeal.imageUrl && (
              <img 
                src={linkedDeal.imageUrl} 
                alt={linkedDeal.title}
                className="w-16 h-16 object-cover rounded"
              />
            )}
            <div className="flex-1">
              <h4 className="font-medium text-blue-600 dark:text-blue-400 mb-1">
                {linkedDeal.title}
              </h4>
              <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
                {linkedDeal.description}
              </p>
            </div>
          </div>
        </Link>
      )}

      <PostActions
        postId={post.id}
        likes={post.likes}
        comments={post.comments}
        onLike={onLike}
        onComment={() => setShowComments(!showComments)}
        onDelete={user && (user.id === post.authorId || user.role === 'admin') ? onDelete : undefined}
        authorId={post.authorId}
      />

      {showComments && (
        <div className="mt-4 pt-4 border-t dark:border-gray-700">
          <PostComments
            postId={post.id}
            comments={post.postComments || []}
            onAddComment={handleAddComment}
          />
        </div>
      )}
    </article>
  );
}