import React from 'react';

function LegalNotice() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Mentions Légales</h1>

      <div className="prose dark:prose-invert">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Éditeur</h2>
          <p>
            SUNDEALS est édité par :<br />
            Email : contact@sundeals.io<br />
            Directeur de la publication : Sébastien Goudon
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Hébergement</h2>
          <p>
            Le site est hébergé par :<br />
            Netlify, Inc.<br />
            2325 3rd Street, Suite 215<br />
            San Francisco, California 94107<br />
            United States
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Propriété intellectuelle</h2>
          <p>
            L'ensemble du site, y compris sa structure et son contenu (textes, images, logos, etc.), 
            est protégé par le droit d'auteur. Toute reproduction ou représentation, totale ou partielle, 
            est interdite sans autorisation préalable.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Protection des données</h2>
          <p>
            Conformément au Règlement Général sur la Protection des Données (RGPD), 
            vous disposez d'un droit d'accès, de rectification et de suppression de vos données. 
            Pour plus d'informations, consultez notre Politique de Confidentialité.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. Cookies</h2>
          <p>
            Le site utilise des cookies pour améliorer l'expérience utilisateur. 
            En naviguant sur le site, vous acceptez leur utilisation. 
            Pour plus d'informations, consultez notre Politique de Confidentialité.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">6. Liens externes</h2>
          <p>
            Le site peut contenir des liens vers des sites externes. 
            SUNDEALS n'est pas responsable du contenu de ces sites et 
            décline toute responsabilité quant à leur contenu.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Contact</h2>
          <p>
            Pour toute question concernant le site :<br />
            Email : contact@sundeals.io
          </p>
        </section>
      </div>
    </div>
  );
}

export default LegalNotice;