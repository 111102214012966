export type NotificationGroup = {
  id: string;
  title: string;
  notifications: Notification[];
  unreadCount: number;
  lastUpdated: Date;
};

export function groupNotifications(notifications: Notification[]): NotificationGroup[] {
  const groups = notifications.reduce((acc, notification) => {
    const key = notification.category || 'other';
    if (!acc[key]) {
      acc[key] = {
        id: key,
        title: getCategoryTitle(key),
        notifications: [],
        unreadCount: 0,
        lastUpdated: new Date()
      };
    }
    acc[key].notifications.push(notification);
    if (!notification.read) acc[key].unreadCount++;
    if (notification.createdAt > acc[key].lastUpdated) {
      acc[key].lastUpdated = notification.createdAt;
    }
    return acc;
  }, {} as Record<string, NotificationGroup>);

  return Object.values(groups).sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
}

function getCategoryTitle(category: string): string {
  switch (category) {
    case 'deal':
      return 'Bons plans';
    case 'comment':
      return 'Commentaires';
    case 'system':
      return 'Système';
    default:
      return 'Autres';
  }
}