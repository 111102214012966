import { clsx } from 'clsx';

interface SkeletonLoaderProps {
  className?: string;
  count?: number;
  animate?: boolean;
}

export function SkeletonLoader({ 
  className, 
  count = 1, 
  animate = true 
}: SkeletonLoaderProps) {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className={clsx(
            'bg-gray-200 dark:bg-gray-700 rounded',
            animate && 'animate-pulse',
            className
          )}
          role="status"
          aria-label="Chargement..."
        />
      ))}
    </>
  );
}