import { useEffect, useRef } from 'react';
import { useNotificationStore } from '../../lib/notifications/store';
import { usePreferences } from '../../lib/preferences/store';

export function NotificationSound() {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { notifications } = useNotificationStore();
  const { preferences } = usePreferences();

  useEffect(() => {
    if (preferences.notifications.push && audioRef.current && notifications.length > 0) {
      const lastNotification = notifications[0];
      if (!lastNotification.read) {
        audioRef.current.play().catch(() => {
          // Ignore autoplay errors
        });
      }
    }
  }, [notifications, preferences.notifications.push]);

  return (
    <audio 
      ref={audioRef} 
      src="/sounds/notification.mp3" 
      preload="auto"
    />
  );
}