import { doc, getDoc, updateDoc, arrayUnion, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { useRewards } from './store';

export class ReferralService {
  static async generateReferralCode(userId: string): Promise<string> {
    // Générer un code unique de 8 caractères
    const code = Math.random().toString(36).substring(2, 10).toUpperCase();
    
    // Sauvegarder le code dans Firestore
    const referralRef = doc(db, 'referrals', userId);
    await setDoc(referralRef, {
      referralCode: code,
      referrals: [], // Initialiser un tableau vide
      createdAt: new Date()
    }, { merge: true });

    return code;
  }

  static async getReferralCode(userId: string): Promise<string> {
    const referralRef = doc(db, 'referrals', userId);
    const referralDoc = await getDoc(referralRef);
    
    if (!referralDoc.exists()) {
      return this.generateReferralCode(userId);
    }

    return referralDoc.data().referralCode;
  }

  static generateReferralLink(code: string): string {
    const baseUrl = window.location.origin;
    return `${baseUrl}/inscription?ref=${code}`;
  }

  static getReferralCodeFromUrl(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('ref');
  }

  static async applyReferralCode(userId: string, code: string): Promise<boolean> {
    try {
      // Vérifier que l'utilisateur n'a pas déjà utilisé un code
      const userRef = doc(db, 'referrals', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists() && userDoc.data().referredBy) {
        throw new Error('Vous avez déjà utilisé un code de parrainage');
      }

      // Trouver le parrain
      const referralsRef = collection(db, 'referrals');
      const q = query(referralsRef, where('referralCode', '==', code));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        throw new Error('Code de parrainage invalide');
      }

      const referrerDoc = snapshot.docs[0];
      const referrerId = referrerDoc.id;
      const referrerData = referrerDoc.data();

      // Vérifier que l'utilisateur ne s'auto-parraine pas
      if (referrerId === userId) {
        throw new Error('Vous ne pouvez pas utiliser votre propre code');
      }

      // Initialiser les tableaux s'ils n'existent pas
      const referrals = referrerData.referrals || [];
      
      // Mettre à jour les informations du parrain
      await updateDoc(doc(db, 'referrals', referrerId), {
        referrals: arrayUnion(userId)
      });

      // Créer ou mettre à jour le document du filleul
      await setDoc(userRef, {
        referredBy: referrerId,
        referralUsed: code,
        createdAt: new Date()
      }, { merge: true });

      // Distribuer les récompenses
      const { addSuncoins } = useRewards.getState();
      
      // Récompense pour le parrain
      await addSuncoins(referrerId, 50, 'Parrainage réussi');
      
      // Récompense pour le filleul
      await addSuncoins(userId, 50, 'Bonus de bienvenue parrainage');

      return true;
    } catch (error) {
      console.error('Error applying referral code:', error);
      throw error;
    }
  }

  // Nouvelle méthode pour vérifier si un code est valide
  static async validateReferralCode(code: string): Promise<boolean> {
    try {
      const referralsRef = collection(db, 'referrals');
      const q = query(referralsRef, where('referralCode', '==', code));
      const snapshot = await getDocs(q);
      
      return !snapshot.empty;
    } catch (error) {
      console.error('Error validating referral code:', error);
      return false;
    }
  }

  // Nouvelle méthode pour obtenir les statistiques de parrainage
  static async getReferralStats(userId: string) {
    try {
      const referralDoc = await getDoc(doc(db, 'referrals', userId));
      if (!referralDoc.exists()) {
        return {
          totalReferrals: 0,
          referrals: [],
          earnedSuncoins: 0
        };
      }

      const data = referralDoc.data();
      return {
        totalReferrals: data.referrals?.length || 0,
        referrals: data.referrals || [],
        earnedSuncoins: (data.referrals?.length || 0) * 50 // 50 SUNCOINS par parrainage
      };
    } catch (error) {
      console.error('Error getting referral stats:', error);
      throw error;
    }
  }
}