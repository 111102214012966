import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { useNotify } from '../../hooks/useNotify';
import { GoogleSignInButton } from './GoogleSignInButton';
import { ReferralService } from '../../lib/rewards/referral';

const loginSchema = z.object({
  email: z.string().email('Email invalide'),
  password: z.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères'),
});

const registerSchema = loginSchema.extend({
  username: z.string().min(3, 'Le nom d\'utilisateur doit contenir au moins 3 caractères'),
  confirmPassword: z.string(),
  referralCode: z.string().optional()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Les mots de passe ne correspondent pas",
  path: ["confirmPassword"],
});

type LoginInputs = z.infer<typeof loginSchema>;
type RegisterInputs = z.infer<typeof registerSchema>;

interface AuthFormProps {
  onSuccess?: () => void;
  initialMode?: 'login' | 'signup';
  referralCode?: string | null;
}

export function AuthForm({ onSuccess, initialMode = 'login', referralCode }: AuthFormProps) {
  const [isLogin, setIsLogin] = useState(initialMode === 'login');
  const [isLoading, setIsLoading] = useState(false);
  const { login, register: registerUser } = useAuth();
  const notify = useNotify();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<LoginInputs | RegisterInputs>({
    resolver: zodResolver(isLogin ? loginSchema : registerSchema),
  });

  useEffect(() => {
    if (referralCode) {
      setIsLogin(false);
      setValue('referralCode', referralCode);
    }
  }, [referralCode, setValue]);

  const onSubmit = async (data: LoginInputs | RegisterInputs) => {
    try {
      setIsLoading(true);

      if (isLogin) {
        const { email, password } = data as LoginInputs;
        await login(email, password);
        notify.success('Connexion réussie !');
      } else {
        const { email, password, username, referralCode } = data as RegisterInputs;
        const userId = await registerUser(email, password, username);
        
        if (referralCode) {
          await ReferralService.applyReferralCode(userId, referralCode);
        }
        
        notify.success('Inscription réussie !');
      }

      onSuccess?.();
    } catch (error) {
      notify.error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <h2 className="text-2xl font-bold text-center mb-6">
        {isLogin ? 'Connexion' : 'Inscription'}
      </h2>
      
      <GoogleSignInButton onSuccess={onSuccess} />
      
      <div className="relative my-6">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300 dark:border-gray-600" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white dark:bg-gray-800 text-gray-500">
            ou continuer avec email
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {!isLogin && (
          <div>
            <label className="block text-sm font-medium mb-1">
              Nom d'utilisateur
            </label>
            <input
              {...register('username')}
              className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
              type="text"
            />
            {errors.username && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                {errors.username.message}
              </p>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium mb-1">
            Email
          </label>
          <input
            {...register('email')}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            type="email"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-600 dark:text-red-400">
              {errors.email.message}
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Mot de passe
          </label>
          <input
            {...register('password')}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            type="password"
          />
          {errors.password && (
            <p className="mt-1 text-sm text-red-600 dark:text-red-400">
              {errors.password.message}
            </p>
          )}
        </div>

        {!isLogin && (
          <div>
            <label className="block text-sm font-medium mb-1">
              Confirmer le mot de passe
            </label>
            <input
              {...register('confirmPassword')}
              className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
              type="password"
            />
            {errors.confirmPassword && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {isLogin ? 'Connexion...' : 'Inscription...'}
            </span>
          ) : (
            isLogin ? 'Se connecter' : 'S\'inscrire'
          )}
        </Button>
      </form>

      <div className="mt-4 text-center">
        <button
          type="button"
          onClick={() => {
            setIsLogin(!isLogin);
          }}
          className="text-sm text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
        >
          {isLogin
            ? 'Pas encore de compte ? S\'inscrire'
            : 'Déjà un compte ? Se connecter'}
        </button>
      </div>
    </div>
  );
}