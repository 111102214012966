import { useState } from 'react';
import { useNotify } from '../../hooks/useNotify';
import { Button } from '../ui/Button';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase/config';

export function NotificationManager() {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim() || !message.trim()) {
      notify.error('Le titre et le message sont requis');
      return;
    }

    try {
      setIsLoading(true);

      // Créer la notification dans Firestore
      const notificationRef = collection(db, 'notifications');
      await addDoc(notificationRef, {
        notification: {
          title: title.trim(),
          body: message.trim(),
        },
        data: {
          url: link.trim() || null,
        },
        createdAt: serverTimestamp(),
        status: 'pending',
        type: 'admin'
      });

      notify.success('Notification envoyée avec succès');
      
      // Reset form
      setTitle('');
      setMessage('');
      setLink('');
    } catch (error) {
      console.error('Error sending notification:', error);
      notify.error('Erreur lors de l\'envoi de la notification');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-6">Envoyer une notification</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Titre *
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            placeholder="Titre de la notification"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Message *
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            rows={4}
            placeholder="Contenu de la notification"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Lien (optionnel)
          </label>
          <input
            type="url"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 dark:bg-gray-700"
            placeholder="https://..."
          />
        </div>

        <Button
          type="submit"
          disabled={isLoading}
          className="w-full"
        >
          {isLoading ? 'Envoi...' : 'Envoyer la notification'}
        </Button>
      </form>
    </div>
  );
}