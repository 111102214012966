import { create } from 'zustand';
import { Deal } from '../types';
import { CategoryFilter, SortOption } from './filters';
import { addDoc, collection, getDocs, query, orderBy, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase/config';

interface DealsState {
  deals: Deal[];
  loading: boolean;
  error: string | null;
  fetchDeals: () => Promise<void>;
  addDeal: (deal: Omit<Deal, 'id' | 'createdAt' | 'updatedAt'>) => Promise<string>;
  updateDeal: (id: string, deal: Partial<Deal>) => void;
  deleteDeal: (id: string) => void;
  setDeals: (deals: Deal[]) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  filterDeals: (search: string, category: CategoryFilter, sort: SortOption) => Deal[];
  initializeRealtimeDeals: () => () => void;
}

export const useDeals = create<DealsState>((set, get) => ({
  deals: [],
  loading: false,
  error: null,
  fetchDeals: async () => {
    try {
      set({ loading: true, error: null });
      const q = query(collection(db, 'deals'), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const deals = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate() || new Date()
      } as Deal));
      set({ deals, loading: false });
    } catch (error) {
      console.error('Error fetching deals:', error);
      set({ error: 'Erreur lors du chargement des bons plans', loading: false });
    }
  },
  addDeal: async (deal) => {
    try {
      set({ loading: true, error: null });
      const docRef = await addDoc(collection(db, 'deals'), {
        ...deal,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        likes: 0,
        dislikes: 0,
        likedBy: [],
        dislikedBy: [],
        status: 'active'
      });
      return docRef.id;
    } catch (error) {
      console.error('Error creating deal:', error);
      set({ error: 'Erreur lors de la création du bon plan', loading: false });
      throw error;
    } finally {
      set({ loading: false });
    }
  },
  updateDeal: (id, updatedDeal) =>
    set(state => ({
      deals: state.deals.map(deal =>
        deal.id === id ? { ...deal, ...updatedDeal } : deal
      ),
    })),
  deleteDeal: (id) =>
    set(state => ({
      deals: state.deals.filter(deal => deal.id !== id),
    })),
  setDeals: (deals) => set({ deals }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  filterDeals: (search, category, sort) => {
    const { deals } = get();
    let filteredDeals = [...deals];

    if (category !== 'all') {
      filteredDeals = filteredDeals.filter(deal => deal.category === category);
    }

    if (search) {
      const searchLower = search.toLowerCase();
      filteredDeals = filteredDeals.filter(
        deal =>
          deal.title.toLowerCase().includes(searchLower) ||
          deal.description.toLowerCase().includes(searchLower)
      );
    }

    switch (sort) {
      case 'popular':
        filteredDeals.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        break;
      case 'controversial':
        filteredDeals.sort((a, b) => 
          Math.abs((b.dislikes || 0) - (b.likes || 0)) - 
          Math.abs((a.dislikes || 0) - (a.likes || 0))
        );
        break;
      case 'recent':
      default:
        filteredDeals.sort((a, b) => 
          (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0)
        );
    }

    return filteredDeals;
  },
  initializeRealtimeDeals: () => {
    set({ loading: true, error: null });
    const q = query(collection(db, 'deals'), orderBy('createdAt', 'desc'));
    
    return onSnapshot(q, 
      (snapshot) => {
        const deals = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate() || new Date()
        } as Deal));
        set({ deals, loading: false });
      },
      (error) => {
        console.error('Deals subscription error:', error);
        set({ error: 'Erreur lors de la mise à jour en temps réel', loading: false });
      }
    );
  }
}));