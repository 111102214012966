import { Heart, MessageSquare, Share2, MoreVertical } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../ui/Button';
import { useAuth } from '../../lib/auth';
import { useNotify } from '../../hooks/useNotify';
import { shareContent } from '../../lib/social/sharing';

interface PostActionsProps {
  postId: string;
  likes: number;
  comments: number;
  isLiked?: boolean;
  onLike: () => void;
  onComment: () => void;
  onDelete?: () => void;
  authorId: string;
}

export function PostActions({
  postId,
  likes,
  comments,
  isLiked,
  onLike,
  onComment,
  onDelete,
  authorId,
}: PostActionsProps) {
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useAuth();
  const notify = useNotify();

  const handleLike = () => {
    if (!user) {
      notify.warning('Connectez-vous pour interagir avec les publications');
      return;
    }
    onLike();
  };

  const handleShare = async () => {
    const url = `${window.location.origin}/commu/posts/${postId}`;
    await shareContent({
      title: 'Publication SUNDEALS',
      text: 'Découvrez cette publication sur SUNDEALS',
      url,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <Button
          variant="ghost"
          size="sm"
          onClick={handleLike}
          className={`flex items-center space-x-2 ${
            isLiked ? 'text-red-600 dark:text-red-400' : ''
          }`}
        >
          <Heart className={`h-4 w-4 ${isLiked ? 'fill-current' : ''}`} />
          <span>{likes}</span>
        </Button>

        <Button
          variant="ghost"
          size="sm"
          onClick={onComment}
          className="flex items-center space-x-2"
        >
          <MessageSquare className="h-4 w-4" />
          <span>{comments}</span>
        </Button>

        <Button
          variant="ghost"
          size="sm"
          onClick={handleShare}
          className="flex items-center space-x-2"
        >
          <Share2 className="h-4 w-4" />
          <span>Partager</span>
        </Button>
      </div>

      {user && (user.id === authorId || user.role === 'admin') && (
        <div className="relative">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setShowMenu(!showMenu)}
          >
            <MoreVertical className="h-4 w-4" />
          </Button>

          {showMenu && (
            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
              {onDelete && (
                <button
                  onClick={() => {
                    onDelete();
                    setShowMenu(false);
                  }}
                  className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  Supprimer
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}