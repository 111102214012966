import { create } from 'zustand';

export type SortOption = 'recent' | 'popular' | 'controversial';
export type CategoryFilter = 'all' | 'deal' | 'promo';

interface FiltersState {
  search: string;
  category: CategoryFilter;
  sort: SortOption;
  setSearch: (search: string) => void;
  setCategory: (category: CategoryFilter) => void;
  setSort: (sort: SortOption) => void;
  reset: () => void;
}

export const useFilters = create<FiltersState>((set) => ({
  search: '',
  category: 'all',
  sort: 'recent',
  setSearch: (search) => set({ search }),
  setCategory: (category) => set({ category }),
  setSort: (sort) => set({ sort }),
  reset: () => set({ search: '', category: 'all', sort: 'recent' }),
}));