import { clsx } from 'clsx';

interface ProgressBarProps {
  progress: number;
  state: 'idle' | 'compressing' | 'uploading' | 'complete' | 'error';
  className?: string;
}

export function ProgressBar({ progress, state, className }: ProgressBarProps) {
  return (
    <div className={clsx('h-1 bg-gray-200 rounded-full overflow-hidden', className)}>
      <div
        className={clsx(
          'h-full transition-all duration-300',
          {
            'bg-blue-500': state === 'uploading' || state === 'compressing',
            'bg-green-500': state === 'complete',
            'bg-red-500': state === 'error'
          }
        )}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}