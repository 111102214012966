import { useEffect } from 'react';
import { useAdmin } from '../lib/admin';
import { AdminStats } from '../components/admin/AdminStats';
import { AdminDealsTable } from '../components/admin/AdminDealsTable';
import { AdminUsersTable } from '../components/admin/AdminUsersTable';
import { NotificationManager } from '../components/admin/NotificationManager';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';

function AdminDashboardPage() {
  const { stats, loading, error, fetchStats, subscribeToStats } = useAdmin();

  useEffect(() => {
    fetchStats();
    const unsubscribe = subscribeToStats();
    return () => unsubscribe();
  }, [fetchStats, subscribeToStats]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-4 sm:p-6">
      <h1 className="text-2xl sm:text-3xl font-bold">Tableau de bord administrateur</h1>
      
      <AdminStats stats={stats} />
      
      <div className="grid gap-6 lg:grid-cols-2">
        <section>
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Derniers bons plans</h2>
          <AdminDealsTable />
        </section>
        
        <section>
          <h2 className="text-xl sm:text-2xl font-bold mb-4">Utilisateurs récents</h2>
          <AdminUsersTable />
        </section>

        <section>
          <NotificationManager />
        </section>
      </div>
    </div>
  );
}

export default AdminDashboardPage;