import { useParams } from 'react-router-dom';
import { UserProfile } from '../components/social/UserProfile';
import { DealList } from '../components/deals/DealList';
import { useDeals } from '../lib/deals';
import { ErrorMessage } from '../components/ui/ErrorMessage';

function UserProfilePage() {
  const { userId } = useParams();
  const { deals } = useDeals();

  if (!userId) {
    return <ErrorMessage message="Utilisateur non trouvé" />;
  }

  const userDeals = deals.filter((deal) => deal.authorId === userId);

  return (
    <div className="space-y-8">
      <UserProfile userId={userId} />
      
      <section>
        <h2 className="text-2xl font-bold mb-6">Bons plans publiés</h2>
        <DealList
          deals={userDeals}
          onLike={(id) => {/* TODO */}}
          onDislike={(id) => {/* TODO */}}
        />
      </section>
    </div>
  );
}

export default UserProfilePage;